/* eslint-disable quotes */
import portfolioImg01 from '@/assets/image/main/portfolio_01.png';
import portfolioImg02 from '@/assets/image/main/portfolio_02.png';
import portfolioImg03 from '@/assets/image/main/portfolio_03.png';
import portfolioImg04 from '@/assets/image/main/portfolio_04.png';
import portfolioImg05 from '@/assets/image/main/portfolio_05.png';
import portfolioImg06 from '@/assets/image/main/portfolio_06.png';

import aboutFadeImg01 from '@/assets/image/about/about_fade01.png';
import aboutFadeImg02 from '@/assets/image/about/about_fade02.png';
import aboutFadeImg03 from '@/assets/image/about/about_fade03.png';
import aboutFadeImg04 from '@/assets/image/about/about_fade04.png';

import aboutBnImg01 from '@/assets/image/about/about_bn_card01.png';
import aboutBnImg02 from '@/assets/image/about/about_bn_card02.png';
import aboutBnImg03 from '@/assets/image/about/about_bn_card03.png';

import clientsLogo01 from '@/assets/image/about/clients_logo01.png';
import clientsLogo02 from '@/assets/image/about/clients_logo02.png';
import clientsLogo03 from '@/assets/image/about/clients_logo03.png';
import clientsLogo04 from '@/assets/image/about/clients_logo04.png';
import clientsLogo05 from '@/assets/image/about/clients_logo05.png';
import clientsLogo06 from '@/assets/image/about/clients_logo06.png';
import clientsLogo07 from '@/assets/image/about/clients_logo07.png';
import clientsLogo08 from '@/assets/image/about/clients_logo08.png';
import clientsLogo09 from '@/assets/image/about/clients_logo09.png';
import clientsLogo10 from '@/assets/image/about/clients_logo10.png';
import clientsLogo11 from '@/assets/image/about/clients_logo11.png';
import clientsLogo12 from '@/assets/image/about/clients_logo12.png';
import clientsLogo13 from '@/assets/image/about/clients_logo13.png';
import clientsLogo14 from '@/assets/image/about/clients_logo14.png';
import clientsLogo15 from '@/assets/image/about/clients_logo15.png';
import clientsLogo16 from '@/assets/image/about/clients_logo16.png';
import clientsLogo17 from '@/assets/image/about/clients_logo17.png';
import clientsLogo18 from '@/assets/image/about/clients_logo18.png';
import clientsLogo19 from '@/assets/image/about/clients_logo19.png';
import clientsLogo20 from '@/assets/image/about/clients_logo20.png';
import clientsLogo21 from '@/assets/image/about/clients_logo21.png';
import clientsLogo22 from '@/assets/image/about/clients_logo22.png';
import clientsLogo23 from '@/assets/image/about/clients_logo23.png';
import clientsLogo24 from '@/assets/image/about/clients_logo24.png';
import clientsLogo25 from '@/assets/image/about/clients_logo25.png';
import clientsLogo26 from '@/assets/image/about/clients_logo26.png';
import clientsLogo27 from '@/assets/image/about/clients_logo27.png';
import clientsLogo28 from '@/assets/image/about/clients_logo28.png';
import clientsLogo29 from '@/assets/image/about/clients_logo29.png';
import clientsLogo30 from '@/assets/image/about/clients_logo30.png';
import clientsLogo31 from '@/assets/image/about/clients_logo31.png';
import clientsLogo32 from '@/assets/image/about/clients_logo32.png';
import clientsLogo33 from '@/assets/image/about/clients_logo33.png';

import portfolioList01 from '@/assets/image/portfolio/portfolio_List_01.png';
import portfolioList02 from '@/assets/image/portfolio/portfolio_List_02.png';
import portfolioListLong02 from '@/assets/image/portfolio/portfolio_List_02_long.png';
import portfolioList03 from '@/assets/image/portfolio/portfolio_List_03.png';
import portfolioListLong03 from '@/assets/image/portfolio/portfolio_List_03_long.png';
import portfolioList04 from '@/assets/image/portfolio/portfolio_List_04.png';
import portfolioList04Small from '@/assets/image/portfolio/portfolio_List_04_small.png';
import portfolioList05 from '@/assets/image/portfolio/portfolio_List_05.png';
import portfolioList05Long from '@/assets/image/portfolio/portfolio_List_05_long.png';
import portfolioList06 from '@/assets/image/portfolio/portfolio_List_06.png';
import portfolioList07 from '@/assets/image/portfolio/portfolio_List_07.png';
import portfolioList07Small from '@/assets/image/portfolio/portfolio_List_07_small.png';
import portfolioList08 from '@/assets/image/portfolio/portfolio_List_08.png';
import portfolioList09 from '@/assets/image/portfolio/portfolio_List_09.png';
import portfolioList09Long from '@/assets/image/portfolio/portfolio_List_09_long.png';
import portfolioList10 from '@/assets/image/portfolio/portfolio_List_10.png';
import portfolioList10Small from '@/assets/image/portfolio/portfolio_List_10_small.png';
import portfolioList11 from '@/assets/image/portfolio/portfolio_List_11.png';
import portfolioList12 from '@/assets/image/portfolio/portfolio_List_12.png';
import portfolioList12Long from '@/assets/image/portfolio/portfolio_List_12_long.png';
import portfolioList13 from '@/assets/image/portfolio/portfolio_List_13.png';
import portfolioList13Small from '@/assets/image/portfolio/portfolio_List_13_small.png';
import portfolioList14 from '@/assets/image/portfolio/portfolio_List_14.png';
import portfolioList15 from '@/assets/image/portfolio/portfolio_List_15.png';
import portfolioList15Long from '@/assets/image/portfolio/portfolio_List_15_long.png';
import portfolioList16 from '@/assets/image/portfolio/portfolio_List_16.png';
import portfolioList16Small from '@/assets/image/portfolio/portfolio_List_16_small.png';
import portfolioList17 from '@/assets/image/portfolio/portfolio_List_17.png';
import portfolioList18 from '@/assets/image/portfolio/portfolio_List_18.png';
import portfolioList18Long from '@/assets/image/portfolio/portfolio_List_18_long.png';
import portfolioList19 from '@/assets/image/portfolio/portfolio_List_19.png';
import portfolioList19Small from '@/assets/image/portfolio/portfolio_List_19_small.png';
import portfolioList20 from '@/assets/image/portfolio/portfolio_List_20.png';
import portfolioList21 from '@/assets/image/portfolio/portfolio_List_21.png';
import portfolioList22 from '@/assets/image/portfolio/portfolio_List_22.png';
import portfolioList22Small from '@/assets/image/portfolio/portfolio_List_22_small.png';
import portfolioList23 from '@/assets/image/portfolio/portfolio_List_23.png';
import portfolioList23Long from '@/assets/image/portfolio/portfolio_List_23_long.png';
import portfolioList24 from '@/assets/image/portfolio/portfolio_List_24.png';
import portfolioList25 from '@/assets/image/portfolio/portfolio_List_25.png';
import portfolioList26 from '@/assets/image/portfolio/portfolio_List_26.png';
import portfolioList27 from '@/assets/image/portfolio/portfolio_List_27.png';
import portfolioList28 from '@/assets/image/portfolio/portfolio_List_28.png';
import portfolioList28Small from '@/assets/image/portfolio/portfolio_List_28_small.png';
import portfolioList29 from '@/assets/image/portfolio/portfolio_List_29.png';
import portfolioList29Long from '@/assets/image/portfolio/portfolio_List_29_long.png';
import portfolioList30 from '@/assets/image/portfolio/portfolio_List_30.png';
import portfolioList31 from '@/assets/image/portfolio/portfolio_List_31.png';
import portfolioList32 from '@/assets/image/portfolio/portfolio_List_32.png';
import portfolioList33 from '@/assets/image/portfolio/portfolio_List_33.png';
import portfolioList34 from '@/assets/image/portfolio/portfolio_List_34.png';
import portfolioList34Small from '@/assets/image/portfolio/portfolio_List_34_small.png';
import portfolioList35 from '@/assets/image/portfolio/portfolio_List_35.png';
import portfolioList36 from '@/assets/image/portfolio/portfolio_List_36.png';
import portfolioList36Long from '@/assets/image/portfolio/portfolio_List_36_long.png';
import portfolioList37 from '@/assets/image/portfolio/portfolio_List_37.png';
import portfolioList37Small from '@/assets/image/portfolio/portfolio_List_37_small.png';
import portfolioList38 from '@/assets/image/portfolio/portfolio_List_38.png';
import portfolioList38Long from '@/assets/image/portfolio/portfolio_List_38_long.png';
import portfolioList39 from '@/assets/image/portfolio/portfolio_List_39.png';
import portfolioList39Long from '@/assets/image/portfolio/portfolio_List_39_long.png';
import portfolioList40 from '@/assets/image/portfolio/portfolio_List_40.png';
import portfolioList40Small from '@/assets/image/portfolio/portfolio_List_40_small.png';
import portfolioList41 from '@/assets/image/portfolio/portfolio_List_41.png';
import portfolioList41Long from '@/assets/image/portfolio/portfolio_List_41_long.png';
import portfolioList42 from '@/assets/image/portfolio/portfolio_List_42.png';
import portfolioList43 from '@/assets/image/portfolio/portfolio_List_43.png';
import portfolioList43Small from '@/assets/image/portfolio/portfolio_List_43_small.png';
import portfolioList44 from '@/assets/image/portfolio/portfolio_List_44.png';
import portfolioList44Long from '@/assets/image/portfolio/portfolio_List_44_long.png';
import portfolioList45 from '@/assets/image/portfolio/portfolio_List_45.png';
import portfolioList46 from '@/assets/image/portfolio/portfolio_List_46.png';
import portfolioList46Small from '@/assets/image/portfolio/portfolio_List_46_small.png';
import portfolioList47 from '@/assets/image/portfolio/portfolio_List_47.png';
import portfolioList48 from '@/assets/image/portfolio/portfolio_List_48.png';
import portfolioList49 from '@/assets/image/portfolio/portfolio_List_49.png';
import portfolioList50 from '@/assets/image/portfolio/portfolio_List_50.png';
import portfolioList51 from '@/assets/image/portfolio/portfolio_List_51.png';
import portfolioList52 from '@/assets/image/portfolio/portfolio_List_52.png';
import portfolioList52Small from '@/assets/image/portfolio/portfolio_List_52_small.png';
import portfolioList53 from '@/assets/image/portfolio/portfolio_List_53.png';
import portfolioList53Long from '@/assets/image/portfolio/portfolio_List_53_long.png';
import portfolioList54 from '@/assets/image/portfolio/portfolio_List_54.png';
import portfolioList55 from '@/assets/image/portfolio/portfolio_List_55.png';
import portfolioList55Small from '@/assets/image/portfolio/portfolio_List_55_small.png';
import portfolioList56 from '@/assets/image/portfolio/portfolio_List_56.png';
import portfolioList56Long from '@/assets/image/portfolio/portfolio_List_56_long.png';
import portfolioList57 from '@/assets/image/portfolio/portfolio_List_57.png';
import portfolioList58 from '@/assets/image/portfolio/portfolio_List_58.png';
import portfolioList59 from '@/assets/image/portfolio/portfolio_List_59.png';
import portfolioList60 from '@/assets/image/portfolio/portfolio_List_60.png';
import portfolioList60Long from '@/assets/image/portfolio/portfolio_List_60_long.png';
import portfolioList61 from '@/assets/image/portfolio/portfolio_List_61.png';
import portfolioList61Small from '@/assets/image/portfolio/portfolio_List_61_small.png';
import portfolioList62 from '@/assets/image/portfolio/portfolio_List_62.png';

import portfolioOther0101 from '@/assets/image/portfolio/other_01_01.png';
import portfolioOther0102 from '@/assets/image/portfolio/other_01_02.png';
import portfolioOther0201 from '@/assets/image/portfolio/other_02_01.png';
import portfolioOther0202 from '@/assets/image/portfolio/other_02_02.png';
import portfolioOther0301 from '@/assets/image/portfolio/other_03_01.png';
import portfolioOther0302 from '@/assets/image/portfolio/other_03_02.png';
import portfolioOther0401 from '@/assets/image/portfolio/other_04_01.png';
import portfolioOther0402 from '@/assets/image/portfolio/other_04_02.png';
import portfolioOther0501 from '@/assets/image/portfolio/other_05_01.png';
import portfolioOther0502 from '@/assets/image/portfolio/other_05_02.png';
import portfolioOther0601 from '@/assets/image/portfolio/other_06_01.png';
import portfolioOther0602 from '@/assets/image/portfolio/other_06_02.png';
import portfolioOther0701 from '@/assets/image/portfolio/other_07_01.png';
import portfolioOther0702 from '@/assets/image/portfolio/other_07_02.png';
import portfolioOther0801 from '@/assets/image/portfolio/other_08_01.png';
import portfolioOther0802 from '@/assets/image/portfolio/other_08_02.png';
import portfolioOther0901 from '@/assets/image/portfolio/other_09_01.png';
import portfolioOther0902 from '@/assets/image/portfolio/other_09_02.png';
import portfolioOther1001 from '@/assets/image/portfolio/other_10_01.png';
import portfolioOther1002 from '@/assets/image/portfolio/other_10_02.png';
import portfolioOther1101 from '@/assets/image/portfolio/other_11_01.png';
import portfolioOther1102 from '@/assets/image/portfolio/other_11_02.png';
import portfolioOther1201 from '@/assets/image/portfolio/other_12_01.png';
import portfolioOther1202 from '@/assets/image/portfolio/other_12_02.png';
import portfolioOther1301 from '@/assets/image/portfolio/other_13_01.png';
import portfolioOther1302 from '@/assets/image/portfolio/other_13_02.png';
import portfolioOther1401 from '@/assets/image/portfolio/other_14_01.png';
import portfolioOther1402 from '@/assets/image/portfolio/other_14_02.png';
import portfolioOther1501 from '@/assets/image/portfolio/other_15_01.png';
import portfolioOther1502 from '@/assets/image/portfolio/other_15_02.png';
import portfolioOther1601 from '@/assets/image/portfolio/other_16_01.png';
import portfolioOther1602 from '@/assets/image/portfolio/other_16_02.png';
import portfolioOther1701 from '@/assets/image/portfolio/other_17_01.png';
import portfolioOther1702 from '@/assets/image/portfolio/other_17_02.png';
import portfolioOther1801 from '@/assets/image/portfolio/other_18_01.png';
import portfolioOther1802 from '@/assets/image/portfolio/other_18_02.png';
import portfolioOther1901 from '@/assets/image/portfolio/other_19_01.png';
import portfolioOther1902 from '@/assets/image/portfolio/other_19_02.png';
import portfolioOther2001 from '@/assets/image/portfolio/other_20_01.png';
import portfolioOther2002 from '@/assets/image/portfolio/other_20_02.png';
import portfolioOther2101 from '@/assets/image/portfolio/other_21_01.png';
import portfolioOther2102 from '@/assets/image/portfolio/other_21_02.png';
import portfolioOther2201 from '@/assets/image/portfolio/other_22_01.png';
import portfolioOther2202 from '@/assets/image/portfolio/other_22_02.png';
import portfolioOther2301 from '@/assets/image/portfolio/other_23_01.png';
import portfolioOther2302 from '@/assets/image/portfolio/other_23_02.png';
import portfolioOther2401 from '@/assets/image/portfolio/other_24_01.png';
import portfolioOther2402 from '@/assets/image/portfolio/other_24_02.png';
import portfolioOther2501 from '@/assets/image/portfolio/other_25_01.png';
import portfolioOther2502 from '@/assets/image/portfolio/other_25_02.png';
import portfolioOther2601 from '@/assets/image/portfolio/other_26_01.png';
import portfolioOther2602 from '@/assets/image/portfolio/other_26_02.png';
import portfolioOther2701 from '@/assets/image/portfolio/other_27_01.png';
import portfolioOther2702 from '@/assets/image/portfolio/other_27_02.png';
import portfolioOther2801 from '@/assets/image/portfolio/other_28_01.png';
import portfolioOther2802 from '@/assets/image/portfolio/other_28_02.png';
import portfolioOther2901 from '@/assets/image/portfolio/other_29_01.png';
import portfolioOther2902 from '@/assets/image/portfolio/other_29_02.png';
import portfolioOther3001 from '@/assets/image/portfolio/other_30_01.png';
import portfolioOther3002 from '@/assets/image/portfolio/other_30_02.png';
import portfolioOther3101 from '@/assets/image/portfolio/other_31_01.png';
import portfolioOther3102 from '@/assets/image/portfolio/other_31_02.png';
import portfolioOther3201 from '@/assets/image/portfolio/other_32_01.png';
import portfolioOther3202 from '@/assets/image/portfolio/other_32_02.png';
import portfolioOther3301 from '@/assets/image/portfolio/other_33_01.png';
import portfolioOther3302 from '@/assets/image/portfolio/other_33_02.png';
import portfolioOther3401 from '@/assets/image/portfolio/other_34_01.png';
import portfolioOther3402 from '@/assets/image/portfolio/other_34_02.png';
import portfolioOther3501 from '@/assets/image/portfolio/other_35_01.png';
import portfolioOther3502 from '@/assets/image/portfolio/other_35_02.png';
import portfolioOther3601 from '@/assets/image/portfolio/other_36_01.png';
import portfolioOther3602 from '@/assets/image/portfolio/other_36_02.png';
import portfolioOther3701 from '@/assets/image/portfolio/other_37_01.png';
import portfolioOther3702 from '@/assets/image/portfolio/other_37_02.png';
import portfolioOther3801 from '@/assets/image/portfolio/other_38_01.png';
import portfolioOther3802 from '@/assets/image/portfolio/other_38_02.png';
import portfolioOther3901 from '@/assets/image/portfolio/other_39_01.png';
import portfolioOther3902 from '@/assets/image/portfolio/other_39_02.png';
import portfolioOther4001 from '@/assets/image/portfolio/other_40_01.png';
import portfolioOther4002 from '@/assets/image/portfolio/other_40_02.png';
import portfolioOther4101 from '@/assets/image/portfolio/other_41_01.png';
import portfolioOther4102 from '@/assets/image/portfolio/other_41_02.png';
import portfolioOther4201 from '@/assets/image/portfolio/other_42_01.png';
import portfolioOther4202 from '@/assets/image/portfolio/other_42_02.png';
import portfolioOther4301 from '@/assets/image/portfolio/other_43_01.png';
import portfolioOther4302 from '@/assets/image/portfolio/other_43_02.png';
import portfolioOther4401 from '@/assets/image/portfolio/other_44_01.png';
import portfolioOther4402 from '@/assets/image/portfolio/other_44_02.png';
import portfolioOther4501 from '@/assets/image/portfolio/other_45_01.png';
import portfolioOther4502 from '@/assets/image/portfolio/other_45_02.png';
import portfolioOther4601 from '@/assets/image/portfolio/other_46_01.png';
import portfolioOther4602 from '@/assets/image/portfolio/other_46_02.png';
import portfolioOther4701 from '@/assets/image/portfolio/other_47_01.png';
import portfolioOther4702 from '@/assets/image/portfolio/other_47_02.png';
import portfolioOther4801 from '@/assets/image/portfolio/other_48_01.png';
import portfolioOther4802 from '@/assets/image/portfolio/other_48_02.png';
import portfolioOther4901 from '@/assets/image/portfolio/other_49_01.png';
import portfolioOther4902 from '@/assets/image/portfolio/other_49_02.png';
import portfolioOther5001 from '@/assets/image/portfolio/other_50_01.png';
import portfolioOther5002 from '@/assets/image/portfolio/other_50_02.png';
import portfolioOther5101 from '@/assets/image/portfolio/other_51_01.png';
import portfolioOther5102 from '@/assets/image/portfolio/other_51_02.png';
import portfolioOther5201 from '@/assets/image/portfolio/other_52_01.png';
import portfolioOther5202 from '@/assets/image/portfolio/other_52_02.png';
import portfolioOther5301 from '@/assets/image/portfolio/other_53_01.png';
import portfolioOther5302 from '@/assets/image/portfolio/other_53_02.png';
import portfolioOther5401 from '@/assets/image/portfolio/other_54_01.png';
import portfolioOther5402 from '@/assets/image/portfolio/other_54_02.png';
import portfolioOther5501 from '@/assets/image/portfolio/other_55_01.png';
import portfolioOther5502 from '@/assets/image/portfolio/other_55_02.png';
import portfolioOther5601 from '@/assets/image/portfolio/other_56_01.png';
import portfolioOther5602 from '@/assets/image/portfolio/other_56_02.png';
import portfolioOther5701 from '@/assets/image/portfolio/other_57_01.png';
import portfolioOther5702 from '@/assets/image/portfolio/other_57_02.png';
import portfolioOther5801 from '@/assets/image/portfolio/other_58_01.png';
import portfolioOther5802 from '@/assets/image/portfolio/other_58_02.png';
import portfolioOther5901 from '@/assets/image/portfolio/other_59_01.png';
import portfolioOther5902 from '@/assets/image/portfolio/other_59_02.png';
import portfolioOther6001 from '@/assets/image/portfolio/other_60_01.png';
import portfolioOther6002 from '@/assets/image/portfolio/other_60_02.png';
import portfolioOther6101 from '@/assets/image/portfolio/other_61_01.png';
import portfolioOther6102 from '@/assets/image/portfolio/other_61_02.png';
import portfolioOther6201 from '@/assets/image/portfolio/other_62_01.png';
import portfolioOther6202 from '@/assets/image/portfolio/other_62_02.png';

import {
    portfolio1,
    portfolio10,
    portfolio11,
    portfolio12,
    portfolio13,
    portfolio14,
    portfolio15,
    portfolio16,
    portfolio17,
    portfolio18,
    portfolio19,
    portfolio2,
    portfolio20,
    portfolio21,
    portfolio22,
    portfolio23,
    portfolio24,
    portfolio25,
    portfolio26,
    portfolio27,
    portfolio28,
    portfolio29,
    portfolio3,
    portfolio30,
    portfolio31,
    portfolio32,
    portfolio33,
    portfolio34,
    portfolio35,
    portfolio36,
    portfolio37,
    portfolio38,
    portfolio39,
    portfolio4,
    portfolio40,
    portfolio41,
    portfolio42,
    portfolio43,
    portfolio44,
    portfolio45,
    portfolio46,
    portfolio47,
    portfolio48,
    portfolio49,
    portfolio5,
    portfolio50,
    portfolio51,
    portfolio52,
    portfolio53,
    portfolio54,
    portfolio55,
    portfolio56,
    portfolio57,
    portfolio58,
    portfolio59,
    portfolio6,
    portfolio60,
    portfolio61,
    portfolio62,
    portfolio7,
    portfolio8,
    portfolio9
} from './image';

// 포트폴리오 컨텐츠
export const portfolioContent = [
    {
        id: 10,
        img: portfolioImg01,
        title: '한미동맹 70주년 기념행사',
        desc: '한미동맹 70주년 감사 오찬 행사 및 추모의 벽 참배 행사 기획 운영',
        desc2: '기획, 연출 및 제작',
        link: '/portfolio/10',
        client: '국가보훈처',
        date: '2023.06',
        place: 'Washington DC USA',
        category: 'Public'
    },
    {
        id: 16,
        img: portfolioImg02,
        title: '2030부산세계박람회 대한항공 특별기 공개행사',
        desc: '2030부산세계박람회 성공유치 기원 대한항공의 특별기 래핑 유치홍보 공개 행사',
        desc2: '기획, 연출 및 제작',
        link: '/portfolio/16',
        client: '대한항공',
        date: '2023.05',
        place: 'Incheon International Airport, Korea',
        category: 'Branding, Public'
    },
    {
        id: 26,
        img: portfolioImg03,
        // eslint-disable-next-line quotes
        title: "2030부산세계박람회 22년도 유치활동 종합용역 'CRUISE82'",
        link: '/portfolio/26',
        desc: '제171차 BIE 총회 계기 파리 현지 홍보',
        desc2: '기획, 연출 및 제작',
        client: '2030부산세계박람회 유치의원회',
        date: '2022.11',
        place: 'Paris, France',
        category: 'Public'
    },
    {
        id: 31,
        img: portfolioImg04,
        title: 'Lamborghini Huracan Tecnica Launch',
        link: '/portfolio/31',
        desc: '람보르기니 우라칸 테크니카 런칭쇼',
        desc2: '기획, 연출 및 제작',
        client: 'Lamborghini',
        date: '2022.07',
        place: 'SJ KUNSTHALLE Seoul, Korea',
        category: 'Branding'
    },
    {
        id: 40,
        img: portfolioImg05,
        title: '한화생명 LIFE ADIVISOR Campaign',
        link: '/portfolio/40',
        desc: 'SPACE, RUNNING, SECOND LIFE 갬페인 기획 및 운영',
        desc2: '기획, 연출 및 제작',
        client: '한화생명',
        date: '2021.08',
        place: 'Seoul, Jeju, Korea',
        category: 'Branding'
    },
    {
        id: 47,
        img: portfolioImg06,
        title: 'SK Telecom Galaxy Note20 Launching Showcase',
        link: '/portfolio/47',
        desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
        desc2: '기획, 연출 및 제작',
        client: 'SK Telecom',
        date: '2020.08',
        place: 'Bitplex, Korea',
        category: 'Branding'
    }
];

// About 콘텐츠
export const aboutFadeContent = [
    {
        img: aboutFadeImg01,
        title: 'Inspire',
        desc: '우리는 지속적으로 창의적이고 혁신적인 아이디어를 찾아내어 동료들을 감동시키고, 더 나아가 고객과 협력 파트너에게 영감을 전하고자 합니다. 우리의 업적과 역량은 타인에게 긍정적인 영향을 미치며, 우리의 모든 행동과 의사결정은 확고한 영감의 힘에서 비롯됩니다.'
    },
    {
        img: aboutFadeImg02,
        title: 'Vibrant',
        desc: '우리는 다양성과 열린 의사소통을 존중하며, 조직 내에서 산출되는 활기찬 에너지를 지속적으로 유지합니다. Vibrant한 분위기에서 창의성이 풍부하게 발휘되며, 우리는 변화에 적응하고 성장하는 동적인 환경을 <br/> 만들어냅니다.'
    },
    {
        img: aboutFadeImg03,
        title: 'Passionate',
        desc: '열정은 우리의 업무에 있어서 불가결한 요소로, 우리는 우리의 임무에 헌신하고, 도전에 무모하지 않으면서도 열정적으로 접근합니다. 각 개인은 자신의 분야에서 깊은 열정을 지닌 전문가로서 끊임없이 성장하며, 함께 일함으로써 우리의 역량을 극대화합니다.'
    },
    {
        img: aboutFadeImg04,
        title: 'Bold',
        desc: '대담함은 우리의 비전을 실현하기 위한 핵심 가치입니다. 어려운 상황에서도 도전을 두려워하지 않고, 새로운 아이디어와 방법을 모색하며, 결단력 있게 실행합니다. 우리는 예측 불가능한 환경에서도 대담하게 <br/> 앞서나가고, 혁신적인 솔루션을 통해 지속적으로 성과를 창출합니다.'
    }
];

// About 비지니스 콘텐츠
export const aboutBnContent = [
    {
        img: aboutBnImg01,
        title: 'Branding',
        desc: 'Promotion <br/> Campaign <br /> Online Live Streaming <br /> Launching Show, Party <br /> Digital Project <br /> Showcase <br /> Viral Event <br /> Festival'
    },
    {
        img: aboutBnImg02,
        title: 'Space',
        desc: 'Pop-up Space Store <br /> Exhibition <br /> Pavilion <br /> Product Showroom'
    },
    {
        img: aboutBnImg03,
        title: 'Public',
        desc: 'EXPO <br /> National Event'
    }
];

// About Clients 콘텐츠
export const clientsContent = [
    clientsLogo01,
    clientsLogo02,
    clientsLogo03,
    clientsLogo04,
    clientsLogo05,
    clientsLogo06,
    clientsLogo07,
    clientsLogo08,
    clientsLogo09,
    clientsLogo10,
    clientsLogo11,
    clientsLogo12,
    clientsLogo13,
    clientsLogo14,
    clientsLogo15,
    clientsLogo16,
    clientsLogo17,
    clientsLogo18,
    clientsLogo19,
    clientsLogo20,
    clientsLogo21,
    clientsLogo22,
    clientsLogo23,
    clientsLogo24,
    clientsLogo25,
    clientsLogo26,
    clientsLogo27,
    clientsLogo28,
    clientsLogo29,
    clientsLogo30,
    clientsLogo31,
    clientsLogo32,
    clientsLogo33
];

// Portfolio List 콘텐츠 (ALL)
export const portfolioListContentALL = [
    [
        {
            id: 1,
            img: portfolioList01,
            title: 'GSAT 2024',
            desc: 'GSAT 2024 총괄 대행 운영',
            desc2: '기획, 연출 및 제작',
            client: '경상남도',
            date: '2024.04',
            place: 'Chagwon, Korea',
            category: 'Public',
            images: portfolio1,
            others: [
                {
                    id: 6,
                    img: portfolioOther0101,
                    title: '부산엑스포 국제 심포지움 만찬',
                    desc: '2023부산세계박람회 유치기원 만찬 및 교류 행사',
                    desc2: '기획, 연출 및 제작',
                    client: '대한상공회의소',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 7,
                    img: portfolioOther0102,
                    title: 'MCOUNTDOWN IN FRANCE <br/> VIP Lounge & Skybox',
                    desc: '2030부산세계박람회 유치기원 콘서트 VIP 행사장',
                    desc2: '기획, 연출 및 제작',
                    client: '대통령실 부산시',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 2,
            img: portfolioList02,
            title: 'CBS 체이즌 투어',
            desc: 'Columbia Business School 초청 행사 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LOTTE Corporation',
            date: '2024.03',
            place: 'Lotte World Tower, Korea',
            category: 'Branding',
            images: portfolio2,
            others: [
                {
                    id: 3,
                    img: portfolioOther0201,
                    title: '대한항공 신 엔진정비공장<br/> 기공식',
                    desc: '기공식 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2024.03',
                    place: 'Incheon, Korea',
                    category: 'Branding'
                },
                {
                    id: 5,
                    img: portfolioOther0202,
                    title: '2023 뷰티플 비전 콘서트',
                    desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '(사)뷰티플마인드',
                    date: '2023.12',
                    place: 'Grand Hyatt Seoul, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 3,
            img: portfolioList03,
            title: '대한항공 신 엔진정비공장<br/> 기공식',
            desc: '기공식 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2024.03',
            place: 'Incheon, Korea',
            category: 'Branding',
            images: portfolio3,
            others: [
                {
                    id: 2,
                    img: portfolioOther0301,
                    title: 'CBS 체이즌 투어',
                    desc: 'Columbia Business School 초청 행사 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LOTTE Corporation',
                    date: '2024.03',
                    place: 'Lotte World Tower, Korea',
                    category: 'Branding'
                },
                {
                    id: 5,
                    img: portfolioOther0302,
                    title: '2023 뷰티플 비전 콘서트',
                    desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '(사)뷰티플마인드',
                    date: '2023.12',
                    place: 'Grand Hyatt Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 4,
            img: portfolioList04,
            title: 'LG IT Innovation Lounge <br/> CES 2024',
            desc: 'CES 2024 LG 전시 라운지 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG',
            date: '2024.01',
            place: 'Las Vegas, USA',
            category: 'SPACE, BRANDING',
            images: portfolio4,
            others: [
                {
                    id: 19,
                    img: portfolioOther0401,
                    title: 'Hannover Messe 2023 <br/> LS Electric',
                    desc: '세계 최대 규모의 산업 전시회 Hannover Messe 2023 전시기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS ELECTRIC',
                    date: '2023.04',
                    place: 'Hannover Fairgrounds, Germany',
                    category: 'Space'
                },
                {
                    id: 22,
                    img: portfolioOther0402,
                    title: 'LS Pavilion InterBattery 2023',
                    desc: '국내 최대 규모의 배터리 전시회 InterBattery 2023 LS Pavilion관 전시기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS Partnership',
                    date: '2023.03',
                    place: 'COEX A/B HALL, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 5,
            img: portfolioList05,
            title: '2023 뷰티플 비전 콘서트',
            desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: '(사)뷰티플마인드',
            date: '2023.12',
            place: 'Grand Hyatt Seoul, Korea',
            category: 'Branding',
            images: portfolio5,
            others: [
                {
                    id: 3,
                    img: portfolioOther0501,
                    title: '대한항공 신 엔진정비공장<br/> 기공식',
                    desc: '기공식 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2024.03',
                    place: 'Incheon, Korea',
                    category: 'Branding'
                },
                {
                    id: 8,
                    img: portfolioOther0502,
                    title: 'KE-DL JV 5th <br/> ANNIVERSARY PROMOTION',
                    desc: '대한항공-델타항공 조인트벤처 5주년 기념 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2023.08',
                    place: 'Seoul Times Square, Korea',
                    category: 'Branding, Space'
                }
            ]
        },
        {
            id: 6,
            img: portfolioList06,
            title: '부산엑스포 국제 심포지움 만찬',
            desc: '2023부산세계박람회 유치기원 만찬 및 교류 행사',
            desc2: '기획, 연출 및 제작',
            client: '대한상공회의소',
            date: '2023.10',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio6,
            others: [
                {
                    id: 1,
                    img: portfolioOther0601,
                    title: 'GSAT 2024',
                    desc: 'GSAT 2024 총괄 대행 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '경상남도',
                    date: '2024.04',
                    place: 'Chagwon, Korea',
                    category: 'Public'
                },
                {
                    id: 7,
                    img: portfolioOther0602,
                    title: 'MCOUNTDOWN IN FRANCE <br/> VIP Lounge & Skybox',
                    desc: '2030부산세계박람회 유치기원 콘서트 VIP 행사장',
                    desc2: '기획, 연출 및 제작',
                    client: '대통령실 부산시',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 7,
            img: portfolioList07,
            title: 'MCOUNTDOWN IN FRANCE <br/> VIP Lounge & Skybox',
            desc: '2030부산세계박람회 유치기원 콘서트 VIP 행사장',
            desc2: '기획, 연출 및 제작',
            client: '대통령실 부산시',
            date: '2023.10',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio7,
            others: [
                {
                    id: 6,
                    img: portfolioOther0701,
                    title: '부산엑스포 국제 심포지움 만찬',
                    desc: '2023부산세계박람회 유치기원 만찬 및 교류 행사',
                    desc2: '기획, 연출 및 제작',
                    client: '대한상공회의소',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 9,
                    img: portfolioOther0702,
                    title: '2023 OMMAGAANG <br/> (오메강 축제) 연계 홍보',
                    desc: '2030부산세계박람회 홍보부스 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.06',
                    place: 'Brussels, Belgium',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 8,
            img: portfolioList08,
            title: 'KE-DL JV 5th <br/> ANNIVERSARY PROMOTION',
            desc: '대한항공-델타항공 조인트벤처 5주년 기념 전시',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2023.08',
            place: 'Seoul Times Square, Korea',
            category: 'Branding, Space',
            images: portfolio8,
            others: [
                {
                    id: 5,
                    img: portfolioOther0801,
                    title: '2023 뷰티플 비전 콘서트',
                    desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '(사)뷰티플마인드',
                    date: '2023.12',
                    place: 'Grand Hyatt Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 16,
                    img: portfolioOther0802,
                    title: '2023부산세계박람회 <br/> 대한항공 특별기 공개행사',
                    desc: '2030부산세계박람회 성공유치 기원 대한항공의 특별기 래핑 유치홍보 공개 행사',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2023.05',
                    place: 'Incheon International Airport, Korea',
                    category: 'Branding, Public'
                }
            ]
        },
        {
            id: 9,
            img: portfolioList09,
            title: '2023 OMMAGAANG <br/> (오메강 축제) 연계 홍보',
            desc: '2030부산세계박람회 홍보부스 운영',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.06',
            place: 'Brussels, Belgium',
            category: 'Public',
            images: portfolio9,
            others: [
                {
                    id: 7,
                    img: portfolioOther0901,
                    title: 'MCOUNTDOWN IN FRANCE <br/> VIP Lounge & Skybox',
                    desc: '2030부산세계박람회 유치기원 콘서트 VIP 행사장',
                    desc2: '기획, 연출 및 제작',
                    client: '대통령실 부산시',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 10,
                    img: portfolioOther0902,
                    title: '한미동맹 70주년 기념행사',
                    desc: '한미동맹 70주년 감사 오찬 행사 및 추모의 벽 참배 행사 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '국가보훈처',
                    date: '2023.06',
                    place: 'Washington DC USA',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 10,
            img: portfolioList10,
            title: '한미동맹 70주년 기념행사',
            desc: '한미동맹 70주년 감사 오찬 행사 및 추모의 벽 참배 행사 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: '국가보훈처',
            date: '2023.06',
            place: 'Washington DC USA',
            category: 'Public',
            images: portfolio10,
            others: [
                {
                    id: 9,
                    img: portfolioOther1001,
                    title: '2023 OMMAGAANG <br/> (오메강 축제) 연계 홍보',
                    desc: '2030부산세계박람회 홍보부스 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.06',
                    place: 'Brussels, Belgium',
                    category: 'Public'
                },
                {
                    id: 11,
                    img: portfolioOther1002,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 23년도 유치활동 종합용역 <br/> 'Walking to 2030'",
                    desc: '제172차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 11,
            img: portfolioList11,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 <br/> 23년도 유치활동 종합용역 <br/> 'Walking to 2030'",
            desc: '제172차 BIE 총회 계기 파리 현지 홍보',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.06',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio11,
            others: [
                {
                    id: 10,
                    img: portfolioOther1101,
                    title: '한미동맹 70주년 기념행사',
                    desc: '한미동맹 70주년 감사 오찬 행사 및 추모의 벽 참배 행사 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '국가보훈처',
                    date: '2023.06',
                    place: 'Washington DC USA',
                    category: 'Public'
                },
                {
                    id: 12,
                    img: portfolioOther1102,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '그린클 챌린지'",
                    desc: '부산엑스포 유치기원 캠페인',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                }
            ]
        },
        {
            id: 12,
            img: portfolioList12,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 캠페인 <br/> '그린클 챌린지'",
            desc: '부산엑스포 유치기원 캠페인',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2023.06',
            place: 'Seoul, Busan, Korea',
            category: 'Public',
            images: portfolio12,
            others: [
                {
                    id: 11,
                    img: portfolioOther1201,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 23년도 유치활동 종합용역 <br/> 'Walking to 2030'",
                    desc: '제172차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 13,
                    img: portfolioOther1202,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 유치홍보 in PARIS '부산다방'",
                    desc: '박람회 성공유지를 위한 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 13,
            img: portfolioList13,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 <br/> 유치홍보 in PARIS '부산다방'",
            desc: '박람회 성공유지를 위한 파리 현지 홍보',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2023.06',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio13,
            others: [
                {
                    id: 12,
                    img: portfolioOther1301,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '그린클 챌린지'",
                    desc: '부산엑스포 유치기원 캠페인',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                },
                {
                    id: 14,
                    img: portfolioOther1302,
                    title: 'K-Finance Week in <br/> Indonesia 2023',
                    desc: '한국-인도네시아 수교 50주년 기념 한국계 금융사 인도네시아 투자 위크',
                    desc2: '기획, 연출 및 제작',
                    client: '외교부',
                    date: '2023.05',
                    place: 'Jakarta, Indonesia',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 14,
            img: portfolioList14,
            title: 'K-Finance Week in <br/> Indonesia 2023',
            desc: '한국-인도네시아 수교 50주년 기념 한국계 금융사 인도네시아 투자 위크',
            desc2: '기획, 연출 및 제작',
            client: '외교부',
            date: '2023.05',
            place: 'Jakarta, Indonesia',
            category: 'Public',
            images: portfolio14,
            others: [
                {
                    id: 13,
                    img: portfolioOther1401,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 유치홍보 in PARIS '부산다방'",
                    desc: '박람회 성공유지를 위한 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 15,
                    img: portfolioOther1402,
                    title: "2030부산세계박람회 유치홍보 <br/> '제834회 함부르크 개항축제'",
                    desc: "2030부산세계박람회 유치홍보 '제834회 함부르크 개항축제' 홍보부스 운영",
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Hamburg, Germany',
                    category: 'Public'
                }
            ]
        },
        {
            id: 15,
            img: portfolioList15,
            title: "2030부산세계박람회 유치홍보 <br/> '제834회 함부르크 개항축제'",
            desc: "2030부산세계박람회 유치홍보 '제834회 함부르크 개항축제' 홍보부스 운영",
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2023.06',
            place: 'Hamburg, Germany',
            category: 'Public',
            images: portfolio15,
            others: [
                {
                    id: 14,
                    img: portfolioOther1501,
                    title: 'K-Finance Week in <br/> Indonesia 2023',
                    desc: '한국-인도네시아 수교 50주년 기념 한국계 금융사 인도네시아 투자 위크',
                    desc2: '기획, 연출 및 제작',
                    client: '외교부',
                    date: '2023.05',
                    place: 'Jakarta, Indonesia',
                    category: 'Public'
                },
                {
                    id: 17,
                    img: portfolioOther1502,
                    // eslint-disable-next-line quotes
                    title: "주프랑스한국문화원 특별기획전 <br/> '엑스포 오마주 1900'",
                    desc: '2030부산세계박람회 홍보관 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.05',
                    place: 'Paris, France',
                    category: 'Public, Space'
                }
            ]
        }
    ],
    [
        {
            id: 16,
            img: portfolioList16,
            title: '2023부산세계박람회 <br/> 대한항공 특별기 공개행사',
            desc: '2030부산세계박람회 성공유치 기원 대한항공의 특별기 래핑 유치홍보 공개 행사',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2023.05',
            place: 'Incheon International Airport, Korea',
            category: 'Branding, Public',
            images: portfolio16,
            others: [
                {
                    id: 8,
                    img: portfolioOther1601,
                    title: 'KE-DL JV 5th <br/> ANNIVERSARY PROMOTION',
                    desc: '대한항공-델타항공 조인트벤처 5주년 기념 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2023.08',
                    place: 'Seoul Times Square, Korea',
                    category: 'Branding, Space'
                },
                {
                    id: 30,
                    img: portfolioOther1602,
                    title: '한화그룹 LIFEPLUS <br/> RUNNING TRIBE',
                    desc: '러닝 어드바이저 및 런트립 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화그룹',
                    date: '2022.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 17,
            img: portfolioList17,
            // eslint-disable-next-line quotes
            title: "주프랑스한국문화원 특별기획전 <br/> '엑스포 오마주 1900'",
            desc: '2030부산세계박람회 홍보관 전시',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.05',
            place: 'Paris, France',
            category: 'Public, Space',
            images: portfolio17,
            others: [
                {
                    id: 15,
                    img: portfolioOther1701,
                    title: "2030부산세계박람회 유치홍보 <br/> '제834회 함부르크 개항축제'",
                    desc: "2030부산세계박람회 유치홍보 '제834회 함부르크 개항축제' 홍보부스 운영",
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Hamburg, Germany',
                    category: 'Public'
                },
                {
                    id: 18,
                    img: portfolioOther1702,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '엑스포 해양관'",
                    desc: '부산엑스포 유치기원 캠페인 아쿠아리움 전시 기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lotte World Aquarium',
                    date: '2023.05',
                    place: 'Busan, Korea',
                    category: 'Public, Space'
                }
            ]
        },
        {
            id: 18,
            img: portfolioList18,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 캠페인 <br/> '엑스포 해양관'",
            desc: '부산엑스포 유치기원 캠페인 아쿠아리움 전시 기획',
            desc2: '기획, 연출 및 제작',
            client: 'Lotte World Aquarium',
            date: '2023.05',
            place: 'Busan, Korea',
            category: 'Public, Space',
            images: portfolio18,
            others: [
                {
                    id: 17,
                    img: portfolioOther1801,
                    // eslint-disable-next-line quotes
                    title: "주프랑스한국문화원 특별기획전 <br/> '엑스포 오마주 1900'",
                    desc: '2030부산세계박람회 홍보관 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.05',
                    place: 'Paris, France',
                    category: 'Public, Space'
                },
                {
                    id: 20,
                    img: portfolioOther1802,
                    title: '2030부산세계박람회 BIE <br/> 현지실사대응',
                    desc: '2030부산세계박람회 유치활동 BIE 실사단 방문 총괄',
                    desc2: '기획, 연출 및 제작',
                    client: '산업통상자원부',
                    date: '2023.04',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 19,
            img: portfolioList19,
            title: 'Hannover Messe 2023 <br/> LS Electric',
            desc: '세계 최대 규모의 산업 전시회 Hannover Messe 2023 전시기획',
            desc2: '기획, 연출 및 제작',
            client: 'LS ELECTRIC',
            date: '2023.04',
            place: 'Hannover Fairgrounds, Germany',
            category: 'Space',
            images: portfolio19,
            others: [
                {
                    id: 24,
                    img: portfolioOther1901,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                },
                {
                    id: 22,
                    img: portfolioOther1902,
                    title: 'LS Pavilion InterBattery 2023',
                    desc: 'InterBattery 전시 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS Partnership',
                    date: '2023.03',
                    place: 'COEX A/B HALL, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 20,
            img: portfolioList20,
            title: '2030부산세계박람회 BIE <br/> 현지실사대응',
            desc: '2030부산세계박람회 유치활동 BIE 실사단 방문 총괄',
            desc2: '기획, 연출 및 제작',
            client: '산업통상자원부',
            date: '2023.04',
            place: 'Seoul, Busan, Korea',
            category: 'Public',
            images: portfolio20,
            others: [
                {
                    id: 18,
                    img: portfolioOther2001,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '엑스포 해양관'",
                    desc: '부산엑스포 유치기원 캠페인 아쿠아리움 전시 기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lotte World Aquarium',
                    date: '2023.05',
                    place: 'Busan, Korea',
                    category: 'Public, Space'
                },
                {
                    id: 21,
                    img: portfolioOther2002,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 유치기원 행사 '광화에서 빛;나이다'",
                    desc: '실사단 방문 계기 엑스포 붐업 프로젝트',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.03',
                    place: 'Gwanghwamun Seoul, Korea',
                    category: 'Public'
                }
            ]
        },
        {
            id: 21,
            img: portfolioList21,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 <br/> 유치기원 행사 '광화에서 빛;나이다'",
            desc: '실사단 방문 계기 엑스포 붐업 프로젝트',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.03',
            place: 'Gwanghwamun Seoul, Korea',
            category: 'Public',
            images: portfolio21,
            others: [
                {
                    id: 20,
                    img: portfolioOther2101,
                    title: '2030부산세계박람회 BIE <br/> 현지실사대응',
                    desc: '2030부산세계박람회 유치활동 BIE 실사단 방문 총괄',
                    desc2: '기획, 연출 및 제작',
                    client: '산업통상자원부',
                    date: '2023.04',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                },
                {
                    id: 25,
                    img: portfolioOther2102,
                    title: '2030부산세계박람회 <br/> 조수미 홍보대사 위촉식',
                    desc: '홍보대사 위족식',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2022.12',
                    place: 'LG Art Center Soule, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 22,
            img: portfolioList22,
            title: 'LS Pavilion InterBattery 2023',
            desc: 'InterBattery 전시 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LS Partnership',
            date: '2023.03',
            place: 'COEX A/B HALL, Korea',
            category: 'Space',
            images: portfolio22,
            others: [
                {
                    id: 19,
                    img: portfolioOther2201,
                    title: 'Hannover Messe 2023 <br/> LS Electric',
                    desc: '세계 최대 규모의 산업 전시회 Hannover Messe 2023 전시기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS ELECTRIC',
                    date: '2023.04',
                    place: 'Hannover Fairgrounds, Germany',
                    category: 'Space'
                },
                {
                    id: 23,
                    img: portfolioOther2202,
                    title: '2023 AHR EXPO LG C&M Exhibition',
                    desc: 'LG C&M 전시 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG C&M',
                    date: '2023.02',
                    place: 'Georgia World Congress Center, USA',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 23,
            img: portfolioList23,
            title: '2023 AHR EXPO LG C&M Exhibition',
            desc: 'LG C&M 전시 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG C&M',
            date: '2023.02',
            place: 'Georgia World Congress Center, USA',
            category: 'Space, Branding',
            images: portfolio23,
            others: [
                {
                    id: 22,
                    img: portfolioOther2301,
                    title: 'LS Pavilion InterBattery 2023',
                    desc: 'InterBattery 전시 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS Partnership',
                    date: '2023.03',
                    place: 'COEX A/B HALL, Korea',
                    category: 'Space'
                },
                {
                    id: 24,
                    img: portfolioOther2302,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                }
            ]
        },
        {
            id: 24,
            img: portfolioList24,
            title: 'LG IT Innovation Lounge CES 2023',
            desc: 'CES 2023 LG 전시 라운지 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG',
            date: '2023.01',
            place: 'Encore at Wynn Las Vegas, USA',
            category: 'Space, Branding',
            images: portfolio24,
            others: [
                {
                    id: 23,
                    img: portfolioOther2401,
                    title: '2023 AHR EXPO LG C&M Exhibition',
                    desc: 'LG C&M 전시 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG C&M',
                    date: '2023.02',
                    place: 'Georgia World Congress Center, USA',
                    category: 'Space'
                },
                {
                    id: 29,
                    img: portfolioOther2402,
                    title: 'ISC 2022 <br/> (Innodep Solution <br/> Conference 2022)',
                    desc: 'ISC 2022 컨퍼런스 전시 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2022.10',
                    place: '63 Building Seoul, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 25,
            img: portfolioList25,
            title: '2030부산세계박람회 <br/> 조수미 홍보대사 위촉식',
            desc: '홍보대사 위족식',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2022.12',
            place: 'LG Art Center Soule, Korea',
            category: 'Public',
            images: portfolio25,
            others: [
                {
                    id: 21,
                    img: portfolioOther2501,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 유치기원 행사 '광화에서 빛;나이다'",
                    desc: '실사단 방문 계기 엑스포 붐업 프로젝트',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.03',
                    place: 'Gwanghwamun Seoul, Korea',
                    category: 'Public'
                },
                {
                    id: 26,
                    img: portfolioOther2502,
                    title: "2030부산세계박람회 <br/> 22년도 유치활동 종합용역 <br/> 'CRUISE82'",
                    desc: '제171차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치의원회',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 26,
            img: portfolioList26,
            title: "2030부산세계박람회 <br/> 22년도 유치활동 종합용역 <br/> 'CRUISE82'",
            desc: '제171차 BIE 총회 계기 파리 현지 홍보',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치의원회',
            date: '2022.11',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio26,
            others: [
                {
                    id: 25,
                    img: portfolioOther2601,
                    title: '2030부산세계박람회 <br/> 조수미 홍보대사 위촉식',
                    desc: '홍보대사 위족식',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2022.12',
                    place: 'LG Art Center Soule, Korea',
                    category: 'Public'
                },
                {
                    id: 27,
                    img: portfolioOther2602,
                    title: '제15회 마포나루 새우젓축제',
                    desc: '축제 총괄 기획 및 연출 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '마포구',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        },
        {
            id: 27,
            img: portfolioList27,
            title: '제15회 마포나루 새우젓축제',
            desc: '축제 총괄 기획 및 연출 운영',
            desc2: '기획, 연출 및 제작',
            client: '마포구',
            date: '2022.11',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio27,
            others: [
                {
                    id: 26,
                    img: portfolioOther2701,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 22년도 유치활동 종합용역 <br/> 'CRUISE82'",
                    desc: '제171차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치의원회',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 28,
                    img: portfolioOther2702,
                    title: '2030부산세계박람회 <br/> X4 SHOWCASE',
                    desc: '홍보대사 팬사인회',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2022.10',
                    place: 'Lotte World Seoul, Korea',
                    category: 'Public, Space'
                }
            ]
        }
    ],
    [
        {
            id: 28,
            img: portfolioList28,
            title: '2030부산세계박람회 <br/> X4 SHOWCASE',
            desc: '홍보대사 팬사인회',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2022.10',
            place: 'Lotte World Seoul, Korea',
            category: 'Public, Space',
            images: portfolio28,
            others: [
                {
                    id: 27,
                    img: portfolioOther2801,
                    title: '제15회 마포나루 새우젓축제',
                    desc: '축제 총괄 기획 및 연출 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '마포구',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 32,
                    img: portfolioOther2802,
                    title: '2022 해외한국문화축제',
                    desc: '전세계 대상 한류 콘텐츠 문화행사 기획',
                    desc2: '기획, 연출 및 제작',
                    client: '문화체육관광부',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 29,
            img: portfolioList29,
            title: 'ISC 2022 <br/> (Innodep Solution <br/> Conference 2022)',
            desc: 'ISC 2022 컨퍼런스 전시 및 운영',
            desc2: '기획, 연출 및 제작',
            client: 'Innodep',
            date: '2022.10',
            place: '63 Building Seoul, Korea',
            category: 'Space',
            images: portfolio29,
            others: [
                {
                    id: 24,
                    img: portfolioOther2901,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                },
                {
                    id: 36,
                    img: portfolioOther2902,
                    title: '포항 환호공원 <br/> 스페이스 워크 제막식',
                    desc: '포항 환호공원 스페이스 워크 제막식 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.11',
                    place: 'Pohang, Korea',
                    category: 'Space, Branding'
                }
            ]
        },
        {
            id: 30,
            img: portfolioList30,
            title: '한화그룹 LIFEPLUS <br/> RUNNING TRIBE',
            desc: '러닝 어드바이저 및 런트립 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: '한화그룹',
            date: '2022.10',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio30,
            others: [
                {
                    id: 33,
                    img: portfolioOther3001,
                    title: '2030부산세계박람회 <br/> 22년도 유치활동 종합용역',
                    desc: '제170차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 31,
                    img: portfolioOther3002,
                    title: 'Lamborghini Huracan <br/> Tecnica Launch',
                    desc: '람보르기니 우라칸 테크니카 런칭쇼',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2022.07',
                    place: 'SJ KUNSTHALLE Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 31,
            img: portfolioList31,
            title: 'Lamborghini Huracan <br/> Tecnica Launch',
            desc: '람보르기니 우라칸 테크니카 런칭쇼',
            desc2: '기획, 연출 및 제작',
            client: 'Lamborghini',
            date: '2022.07',
            place: 'SJ KUNSTHALLE Seoul, Korea',
            category: 'Branding',
            images: portfolio31,
            others: [
                {
                    id: 30,
                    img: portfolioOther3101,
                    title: '한화그룹 LIFEPLUS <br/> RUNNING TRIBE',
                    desc: '러닝 어드바이저 및 런트립 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화그룹',
                    date: '2022.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 34,
                    img: portfolioOther3102,
                    title: '故일우 조양호 회장 추모사진전',
                    desc: '대한항공 추모사진전 제막식 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2022.06',
                    place: '서울 대한항공 서소문 사옥, Korea',
                    category: 'Branding, Space'
                }
            ]
        }
    ],
    [
        {
            id: 32,
            img: portfolioList32,
            title: '2022 해외한국문화축제',
            desc: '전세계 대상 한류 콘텐츠 문화행사 기획',
            desc2: '기획, 연출 및 제작',
            client: '문화체육관광부',
            date: '2022.06',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio32,
            others: [
                {
                    id: 28,
                    img: portfolioOther3201,
                    title: '2030부산세계박람회 <br/> X4 SHOWCASE',
                    desc: '홍보대사 팬사인회',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2022.10',
                    place: 'Lotte World Seoul, Korea',
                    category: 'Public, Space'
                },
                {
                    id: 33,
                    img: portfolioOther3202,
                    title: '2030부산세계박람회 <br/> 22년도 유치활동 종합용역',
                    desc: '제170차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        },
        {
            id: 33,
            img: portfolioList33,
            title: '2030부산세계박람회 <br/> 22년도 유치활동 종합용역',
            desc: '제170차 BIE 총회 계기 파리 현지 홍보',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2022.06',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio33,
            others: [
                {
                    id: 32,
                    img: portfolioOther3301,
                    title: '2022 해외한국문화축제',
                    desc: '전세계 대상 한류 콘텐츠 문화행사 기획',
                    desc2: '기획, 연출 및 제작',
                    client: '문화체육관광부',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 38,
                    img: portfolioOther3302,
                    title: '김포평화축제 <br/> 주간 총괄 기획 · 제작',
                    desc: '주간 총괄 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '김포 문화 재단',
                    date: '2021.10',
                    place: 'Gimpo Art Village, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 34,
            img: portfolioList34,
            title: '故일우 조양호 회장 추모사진전',
            desc: '대한항공 추모사진전 제막식 및 전시',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2022.06',
            place: '서울 대한항공 서소문 사옥, Korea',
            category: 'Branding, Space',
            images: portfolio34,
            others: [
                {
                    id: 31,
                    img: portfolioOther3401,
                    title: 'Lamborghini Huracan <br/> Tecnica Launch',
                    desc: '람보르기니 우라칸 테크니카 런칭쇼',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2022.07',
                    place: 'SJ KUNSTHALLE Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 35,
                    img: portfolioOther3402,
                    title: 'Lamborghini Seoul <br/> Year End Party',
                    desc: '람보르기니 이어앤드 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2021.12',
                    place: 'Lamborghini Seoul Showroom, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 35,
            img: portfolioList35,
            title: 'Lamborghini Seoul <br/> Year End Party',
            desc: '람보르기니 이어앤드 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: 'Lamborghini',
            date: '2021.12',
            place: 'Lamborghini Seoul Showroom, Korea',
            category: 'Branding',
            images: portfolio35,
            others: [
                {
                    id: 34,
                    img: portfolioOther3501,
                    title: '故일우 조양호 회장 추모사진전',
                    desc: '대한항공 추모사진전 제막식 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2022.06',
                    place: '서울 대한항공 서소문 사옥, Korea',
                    category: 'Branding, Space'
                },
                {
                    id: 37,
                    img: portfolioOther3502,
                    title: '애기봉 평화생태공원 개관 기념행사',
                    desc: '개관식 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: '김포 문화 재단',
                    date: '2021.10',
                    place: 'Aegibong Peace ecoPark, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 36,
            img: portfolioList36,
            title: '포항 환호공원 <br/> 스페이스 워크 제막식',
            desc: '포항 환호공원 스페이스 워크 제막식 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: 'POSCO',
            date: '2021.11',
            place: 'Pohang, Korea',
            category: 'Space, Branding',
            images: portfolio36,
            others: [
                {
                    id: 29,
                    img: portfolioOther3601,
                    title: 'ISC 2022 <br/> (Innodep Solution <br/> Conference 2022)',
                    desc: 'ISC 2022 컨퍼런스 전시 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2022.10',
                    place: '63 Building Seoul, Korea',
                    category: 'Space'
                },
                {
                    id: 39,
                    img: portfolioOther3602,
                    title: '포스코 수소모빌리티+쇼',
                    desc: '2021 포스코 수소모빌리티 쇼 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.09',
                    place: 'Kintex Hall, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 37,
            img: portfolioList37,
            title: '애기봉 평화생태공원 개관 기념행사',
            desc: '개관식 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: '김포 문화 재단',
            date: '2021.10',
            place: 'Aegibong Peace ecoPark, Korea',
            category: 'Branding',
            images: portfolio37,
            others: [
                {
                    id: 35,
                    img: portfolioOther3701,
                    title: 'Lamborghini Seoul <br/> Year End Party',
                    desc: '람보르기니 이어앤드 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2021.12',
                    place: 'Lamborghini Seoul Showroom, Korea',
                    category: 'Branding'
                },
                {
                    id: 40,
                    img: portfolioOther3702,
                    title: '한화생명 LIFE ADIVISOR <br/> Campaign',
                    desc: 'SPACE, RUNNING, SECOND LIFE 갬페인 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화생명',
                    date: '2021.08',
                    place: 'Seoul, Jeju, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 38,
            img: portfolioList38,
            title: '김포평화축제 <br/> 주간 총괄 기획 · 제작',
            desc: '주간 총괄 기획 및 제작',
            desc2: '기획, 연출 및 제작',
            client: '김포 문화 재단',
            date: '2021.10',
            place: 'Gimpo Art Village, Korea',
            category: 'Public',
            images: portfolio38,
            others: [
                {
                    id: 33,
                    img: portfolioOther3801,
                    title: '2030부산세계박람회 <br/> 22년도 유치활동 종합용역',
                    desc: '제170차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 48,
                    img: portfolioOther3802,
                    title: '2030부산세계박람회 <br/> 마스터플랜 수립',
                    desc: '주제개발, 회장조성, 전시연출 등 마스터플랜 수립',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2020.06',
                    place: 'Seoul, Korea',
                    category: 'Public'
                }
            ]
        },
        {
            id: 39,
            img: portfolioList39,
            title: '포스코 수소모빌리티+쇼',
            desc: '2021 포스코 수소모빌리티 쇼 전시',
            desc2: '기획, 연출 및 제작',
            client: 'POSCO',
            date: '2021.09',
            place: 'Kintex Hall, Korea',
            category: 'Space',
            images: portfolio39,
            others: [
                {
                    id: 36,
                    img: portfolioOther3901,
                    title: '포항 환호공원 <br/> 스페이스 워크 제막식',
                    desc: '포항 환호공원 스페이스 워크 제막식 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.11',
                    place: 'Pohang, Korea',
                    category: 'Space, Branding'
                },
                {
                    id: 45,
                    img: portfolioOther3902,
                    title: 'ISC 2020 Smartcity <br/> e-Showference',
                    desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2020.10',
                    place: 'KINTEX, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 40,
            img: portfolioList40,
            title: '한화생명 LIFE ADIVISOR <br/> Campaign',
            desc: 'SPACE, RUNNING, SECOND LIFE 갬페인 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: '한화생명',
            date: '2021.08',
            place: 'Seoul, Jeju, Korea',
            category: 'Branding',
            images: portfolio40,
            others: [
                {
                    id: 37,
                    img: portfolioOther4001,
                    title: '애기봉 평화생태공원 개관 기념행사',
                    desc: '개관식 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: '김포 문화 재단',
                    date: '2021.10',
                    place: 'Aegibong Peace ecoPark, Korea',
                    category: 'Branding'
                },
                {
                    id: 41,
                    img: portfolioOther4002,
                    title: '교보생명 Vision 2025 선포식',
                    desc: 'Vision2025 선포식 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '교보생명',
                    date: '2021.04',
                    place: 'Kyobo Building Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 41,
            img: portfolioList41,
            title: '교보생명 Vision 2025 선포식',
            desc: 'Vision2025 선포식 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: '교보생명',
            date: '2021.04',
            place: 'Kyobo Building Korea',
            category: 'Branding',
            images: portfolio41,
            others: [
                {
                    id: 40,
                    img: portfolioOther4101,
                    title: '한화생명 LIFE ADIVISOR <br/> Campaign',
                    desc: 'SPACE, RUNNING, SECOND LIFE 갬페인 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화생명',
                    date: '2021.08',
                    place: 'Seoul, Jeju, Korea',
                    category: 'Branding'
                },
                {
                    id: 42,
                    img: portfolioOther4102,
                    title: 'SK Telecom Galaxy A <br/> QUANTUM2 Viral Event',
                    desc: '갤럭시A 퀸텀2 한정 바이럴 유튜브 콘텐츠 제작',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2021.04',
                    place: 'Gwangju National Science Museum, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 42,
            img: portfolioList42,
            title: 'SK Telecom Galaxy A <br/> QUANTUM2 Viral Event',
            desc: '갤럭시A 퀸텀2 한정 바이럴 유튜브 콘텐츠 제작',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2021.04',
            place: 'Gwangju National Science Museum, Korea',
            category: 'Branding',
            images: portfolio42,
            others: [
                {
                    id: 41,
                    img: portfolioOther4201,
                    title: '교보생명 Vision 2025 선포식',
                    desc: 'Vision2025 선포식 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '교보생명',
                    date: '2021.04',
                    place: 'Kyobo Building Korea',
                    category: 'Branding'
                },
                {
                    id: 43,
                    img: portfolioOther4202,
                    // eslint-disable-next-line quotes
                    title: "SK Telecom 수고0페 '0원한 응원전'",
                    desc: '수능 계기 프로모션 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.12',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 43,
            img: portfolioList43,
            // eslint-disable-next-line quotes
            title: "SK Telecom 수고0페 '0원한 응원전'",
            desc: '수능 계기 프로모션 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2020.12',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio43,
            others: [
                {
                    id: 42,
                    img: portfolioOther4301,
                    title: 'SK Telecom Galaxy A <br/> QUANTUM2 Viral Event',
                    desc: '갤럭시A 퀸텀2 한정 바이럴 유튜브 콘텐츠 제작',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2021.04',
                    place: 'Gwangju National Science Museum, Korea',
                    category: 'Branding'
                },
                {
                    id: 44,
                    img: portfolioOther4302,
                    title: 'SK Telecom iPhone12 <br/> Launching Party',
                    desc: '아이폰12 런칭파티 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 44,
            img: portfolioList44,
            title: 'SK Telecom iPhone12 <br/> Launching Party',
            desc: '아이폰12 런칭파티 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2020.10',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio44,
            others: [
                {
                    id: 43,
                    img: portfolioOther4401,
                    // eslint-disable-next-line quotes
                    title: "SK Telecom 수고0페 '0원한 응원전'",
                    desc: '수능 계기 프로모션 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.12',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 46,
                    img: portfolioOther4402,
                    title: 'SK Telecom 플래그십 스토어 <br/> T Factory Launching',
                    desc: '온라인 기자간담회 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.08',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 45,
            img: portfolioList45,
            title: 'ISC 2020 Smartcity <br/> e-Showference',
            desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
            desc2: '기획, 연출 및 제작',
            client: 'Innodep',
            date: '2020.10',
            place: 'KINTEX, Korea',
            category: 'Space',
            images: portfolio45,
            others: [
                {
                    id: 39,
                    img: portfolioOther4501,
                    title: '포스코 수소모빌리티+쇼',
                    desc: '2021 포스코 수소모빌리티 쇼 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.09',
                    place: 'Kintex Hall, Korea',
                    category: 'Space'
                },
                {
                    id: 50,
                    img: portfolioOther4502,
                    // eslint-disable-next-line quotes
                    title: "2021 여수 미디어아트뮤지엄 <br/> '녹테마레'콘텐츠 기획ㆍ제작",
                    desc: '콘텐츠 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '(주) 신원',
                    date: '2020.03',
                    place: 'Yeosu, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 46,
            img: portfolioList46,
            title: 'SK Telecom 플래그십 스토어 <br/> T Factory Launching',
            desc: '온라인 기자간담회 운영',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2020.08',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio46,
            others: [
                {
                    id: 44,
                    img: portfolioOther4601,
                    title: 'SK Telecom iPhone12 <br/> Launching Party',
                    desc: '아이폰12 런칭파티 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 47,
                    img: portfolioOther4602,
                    title: 'SK Telecom Galaxy Note20 <br/> Launching Showcase',
                    desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.08',
                    place: 'Bitplex, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 47,
            img: portfolioList47,
            title: 'SK Telecom Galaxy Note20 <br/> Launching Showcase',
            desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2020.08',
            place: 'Bitplex, Korea',
            category: 'Branding',
            images: portfolio47,
            others: [
                {
                    id: 46,
                    img: portfolioOther4701,
                    title: 'SK Telecom 플래그십 스토어 <br/> T Factory Launching',
                    desc: '온라인 기자간담회 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.08',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 49,
                    img: portfolioOther4702,
                    // eslint-disable-next-line quotes
                    title: "한진 CSV 프로젝트 '식탁위수박'",
                    desc: '함안수박 팝업스토어 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '한진',
                    date: '2020.05',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 48,
            img: portfolioList48,
            title: '2030부산세계박람회 <br/> 마스터플랜 수립',
            desc: '주제개발, 회장조성, 전시연출 등 마스터플랜 수립',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2020.06',
            place: 'Seoul, Korea',
            category: 'Public',
            images: portfolio48,
            others: [
                {
                    id: 38,
                    img: portfolioOther4801,
                    title: '김포평화축제 <br/> 주간 총괄 기획 · 제작',
                    desc: '주간 총괄 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '김포 문화 재단',
                    date: '2021.10',
                    place: 'Gimpo Art Village, Korea',
                    category: 'Public'
                },
                {
                    id: 55,
                    img: portfolioOther4802,
                    title: '한국마사회 경마공원 콘서트 하반기',
                    desc: '콘서트 기획 및 연출',
                    desc2: '',
                    client: '한국마사회',
                    date: '2019.09',
                    place: 'Gwacheon, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 49,
            img: portfolioList49,
            // eslint-disable-next-line quotes
            title: "한진 CSV 프로젝트 '식탁위수박'",
            desc: '함안수박 팝업스토어 기획 및 제작',
            desc2: '기획, 연출 및 제작',
            client: '한진',
            date: '2020.05',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio49,
            others: [
                {
                    id: 47,
                    img: portfolioOther4901,
                    title: 'SK Telecom Galaxy Note20 <br/> Launching Showcase',
                    desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.08',
                    place: 'Bitplex, Korea',
                    category: 'Branding'
                },
                {
                    id: 51,
                    img: portfolioOther4902,
                    title: '파라다이스 카지노 <br/> 중국 VIP 춘절 디너쇼',
                    desc: '파라다이스 카지노 중국 VIP 춘절 디너쇼 기획 연출 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '파라다이스',
                    date: '2020.01',
                    place: 'Grand Walkerhill Seoul, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 50,
            img: portfolioList50,
            // eslint-disable-next-line quotes
            title: "2021 여수 미디어아트뮤지엄 <br/> '녹테마레'콘텐츠 기획ㆍ제작",
            desc: '콘텐츠 기획 및 제작',
            desc2: '기획, 연출 및 제작',
            client: '(주) 신원',
            date: '2020.03',
            place: 'Yeosu, Korea',
            category: 'Space',
            images: portfolio50,
            others: [
                {
                    id: 45,
                    img: portfolioOther5001,
                    title: 'ISC 2020 Smartcity <br/> e-Showference',
                    desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2020.10',
                    place: 'KINTEX, Korea',
                    category: 'Space'
                },
                {
                    id: 24,
                    img: portfolioOther5002,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                }
            ]
        },
        {
            id: 51,
            img: portfolioList51,
            title: '파라다이스 카지노 <br/> 중국 VIP 춘절 디너쇼',
            desc: '파라다이스 카지노 중국 VIP 춘절 디너쇼 기획 연출 및 제작',
            desc2: '기획, 연출 및 제작',
            client: '파라다이스',
            date: '2020.01',
            place: 'Grand Walkerhill Seoul, Korea',
            category: 'Branding',
            images: portfolio51,
            others: [
                {
                    id: 49,
                    img: portfolioOther5101,
                    // eslint-disable-next-line quotes
                    title: "한진 CSV 프로젝트 '식탁위수박'",
                    desc: '함안수박 팝업스토어 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '한진',
                    date: '2020.05',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 52,
                    img: portfolioOther5102,
                    title: '파라다이스 시티 <br/> 2020 COUNT DOWN',
                    desc: '카지노 신년맞이 공연 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: '파라다이스',
                    date: '2019.12',
                    place: 'Incheon, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 52,
            img: portfolioList52,
            title: '파라다이스 시티 <br/> 2020 COUNT DOWN',
            desc: '카지노 신년맞이 공연 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: '파라다이스',
            date: '2019.12',
            place: 'Incheon, Korea',
            category: 'Branding',
            images: portfolio52,
            others: [
                {
                    id: 51,
                    img: portfolioOther5201,
                    title: '파라다이스 카지노 <br/> 중국 VIP 춘절 디너쇼',
                    desc: '파라다이스 카지노 중국 VIP 춘절 디너쇼 기획 연출 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '파라다이스',
                    date: '2020.01',
                    place: 'Grand Walkerhill Seoul, Korea',
                    category: 'Space'
                },
                {
                    id: 53,
                    img: portfolioOther5202,
                    title: 'SK Telecom 캐럴 프로젝트',
                    desc: '캐럴 프로젝트 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2019.12',
                    place: 'Seoul, Busan, Gwangju, Daejeon, Daegu, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 53,
            img: portfolioList53,
            title: 'SK Telecom 캐럴 프로젝트',
            desc: '캐럴 프로젝트 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2019.12',
            place: 'Seoul, Busan, Gwangju, Daejeon, Daegu, Korea',
            category: 'Branding',
            images: portfolio53,
            others: [
                {
                    id: 52,
                    img: portfolioOther5301,
                    title: '파라다이스 시티 <br/> 2020 COUNT DOWN',
                    desc: '카지노 신년맞이 공연 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: '파라다이스',
                    date: '2019.12',
                    place: 'Incheon, Korea',
                    category: 'Branding'
                },
                {
                    id: 54,
                    img: portfolioOther5302,
                    title: '파라다이스 카지노 워커힐 <br/> 국제 송년 디너쇼',
                    desc: '국제 VIP 대상 공연 기획 및 연출',
                    desc2: '',
                    client: '파라다이스',
                    date: '2019.06',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 54,
            img: portfolioList54,
            title: '파라다이스 카지노 워커힐 <br/> 국제 송년 디너쇼',
            desc: '국제 VIP 대상 공연 기획 및 연출',
            desc2: '',
            client: '파라다이스',
            date: '2019.06',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio54,
            others: [
                {
                    id: 53,
                    img: portfolioOther5401,
                    title: 'SK Telecom 캐럴 프로젝트',
                    desc: '캐럴 프로젝트 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2019.12',
                    place: 'Seoul, Busan, Gwangju, Daejeon, Daegu, Korea',
                    category: 'Branding'
                },
                {
                    id: 57,
                    img: portfolioOther5402,
                    title: '파라다이스 카지노 워커힐 송이파티',
                    desc: '일본 VIP 대상 공연 기획 및 연출',
                    desc2: '',
                    client: '파라다이스',
                    date: '2019.09',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 55,
            img: portfolioList55,
            title: '한국마사회 경마공원 콘서트 하반기',
            desc: '콘서트 기획 및 연출',
            desc2: '',
            client: '한국마사회',
            date: '2019.09',
            place: 'Gwacheon, Korea',
            category: 'Public',
            images: portfolio55,
            others: [
                {
                    id: 48,
                    img: portfolioOther5501,
                    title: '2030부산세계박람회 <br/> 마스터플랜 수립',
                    desc: '주제개발, 회장조성, 전시연출 등 마스터플랜 수립',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2020.06',
                    place: 'Seoul, Korea',
                    category: 'Public'
                },
                {
                    id: 56,
                    img: portfolioOther5502,
                    title: '강남페스티벌 인터내셔널 프린지',
                    desc: '2019 강남페스티벌 인터네셔널 프린지 공연 기획 및 운영',
                    desc2: '',
                    client: '강남구',
                    date: '2019.09',
                    place: 'Seoul, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 56,
            img: portfolioList56,
            title: '강남페스티벌 인터내셔널 프린지',
            desc: '2019 강남페스티벌 인터네셔널 프린지 공연 기획 및 운영',
            desc2: '',
            client: '강남구',
            date: '2019.09',
            place: 'Seoul, Korea',
            category: 'Public',
            images: portfolio56,
            others: [
                {
                    id: 55,
                    img: portfolioList55,
                    title: '한국마사회 경마공원 콘서트 하반기',
                    desc: '콘서트 기획 및 연출',
                    desc2: '',
                    client: '한국마사회',
                    date: '2019.09',
                    place: 'Gwacheon, Korea',
                    category: 'Public'
                },
                {
                    id: 60,
                    img: portfolioOther5602,
                    title: '한국마사회 경마공원 콘서트 상반기',
                    desc: '콘서트 기획 및 연출',
                    desc2: '',
                    client: '한국마사회',
                    date: '2019.06',
                    place: 'Gwacheon, Korea',
                    category: 'Public'
                }
            ]
        },
        {
            id: 57,
            img: portfolioList57,
            title: '파라다이스 카지노 워커힐 송이파티',
            desc: '일본 VIP 대상 공연 기획 및 연출',
            desc2: '',
            client: '파라다이스',
            date: '2019.09',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio57,
            others: [
                {
                    id: 54,
                    img: portfolioOther5701,
                    title: '파라다이스 카지노 워커힐 <br/> 국제 송년 디너쇼',
                    desc: '국제 VIP 대상 공연 기획 및 연출',
                    desc2: '',
                    client: '파라다이스',
                    date: '2019.06',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 58,
                    img: portfolioOther5702,
                    title: '한진 농가소득지원 프로젝트',
                    desc: '모바일 판로개척 및 서포터즈 운영',
                    desc2: '',
                    client: '한진',
                    date: '2019.08',
                    place: 'Haman, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 58,
            img: portfolioList58,
            title: '한진 농가소득지원 프로젝트',
            desc: '모바일 판로개척 및 서포터즈 운영',
            desc2: '',
            client: '한진',
            date: '2019.08',
            place: 'Haman, Korea',
            category: 'Branding',
            images: portfolio58,
            others: [
                {
                    id: 57,
                    img: portfolioOther5801,
                    title: '파라다이스 카지노 워커힐 송이파티',
                    desc: '일본 VIP 대상 공연 기획 및 연출',
                    desc2: '',
                    client: '파라다이스',
                    date: '2019.09',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 59,
                    img: portfolioOther5802,
                    title: 'SK Telecom 5GX <br/> Summer Festival',
                    desc: '콜비지 프로모션 기획 및 운영',
                    desc2: '',
                    client: 'SK Telecom',
                    date: '2019.08',
                    place: 'Busan, GangWon, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 59,
            img: portfolioList59,
            title: 'SK Telecom 5GX <br/> Summer Festival',
            desc: '콜비지 프로모션 기획 및 운영',
            desc2: '',
            client: 'SK Telecom',
            date: '2019.08',
            place: 'Busan, GangWon, Korea',
            category: 'Branding',
            images: portfolio59,
            others: [
                {
                    id: 58,
                    img: portfolioOther5901,
                    title: '한진 농가소득지원 프로젝트',
                    desc: '모바일 판로개척 및 서포터즈 운영',
                    desc2: '',
                    client: '한진',
                    date: '2019.08',
                    place: 'Haman, Korea',
                    category: 'Branding'
                },
                {
                    id: 61,
                    img: portfolioOther5902,
                    title: '교보생명 2019 고객보장대상',
                    desc: '고객보장대상 총괄 기획 및 운영',
                    desc2: '',
                    client: '교보생명',
                    date: '2019.04',
                    place: '천안 교보생명 계성원, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 60,
            img: portfolioList60,
            title: '한국마사회 경마공원 콘서트 상반기',
            desc: '콘서트 기획 및 연출',
            desc2: '',
            client: '한국마사회',
            date: '2019.06',
            place: 'Gwacheon, Korea',
            category: 'Public',
            images: portfolio60,
            others: [
                {
                    id: 56,
                    img: portfolioOther6001,
                    title: '강남페스티벌 인터내셔널 프린지',
                    desc: '2019 강남페스티벌 인터네셔널 프린지 공연 기획 및 운영',
                    desc2: '',
                    client: '강남구',
                    date: '2019.09',
                    place: 'Seoul, Korea',
                    category: 'Public'
                },
                {
                    id: 1,
                    img: portfolioOther6002,
                    title: 'GSAT 2024',
                    desc: 'GSAT 2024 총괄 대행 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '경상남도',
                    date: '2024.04',
                    place: 'Chagwon, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 61,
            img: portfolioList61,
            title: '교보생명 2019 고객보장대상',
            desc: '고객보장대상 총괄 기획 및 운영',
            desc2: '',
            client: '교보생명',
            date: '2019.04',
            place: '천안 교보생명 계성원, Korea',
            category: 'Branding',
            images: portfolio61,
            others: [
                {
                    id: 59,
                    img: portfolioOther6101,
                    title: 'SK Telecom 5GX <br/> Summer Festival',
                    desc: '콜비지 프로모션 기획 및 운영',
                    desc2: '',
                    client: 'SK Telecom',
                    date: '2019.08',
                    place: 'Busan, GangWon, Korea',
                    category: 'Branding'
                },
                {
                    id: 62,
                    img: portfolioOther6102,
                    title: '대한항공 50년사 편찬 기념식',
                    desc: '대한항공 50년사 편찬 기념식 기획 및 운영',
                    desc2: '',
                    client: '대한항공',
                    date: '2019.03',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 62,
            img: portfolioList62,
            title: '대한항공 50년사 편찬 기념식',
            desc: '대한항공 50년사 편찬 기념식 기획 및 운영',
            desc2: '',
            client: '대한항공',
            date: '2019.03',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio62,
            others: [
                {
                    id: 61,
                    img: portfolioOther6201,
                    title: '교보생명 2019 고객보장대상',
                    desc: '고객보장대상 총괄 기획 및 운영',
                    desc2: '',
                    client: '교보생명',
                    date: '2019.04',
                    place: '천안 교보생명 계성원, Korea',
                    category: 'Branding'
                },
                {
                    id: 2,
                    img: portfolioOther6202,
                    title: 'CBS 체이즌 투어',
                    desc: 'Columbia Business School 초청 행사 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LOTTE Corporation',
                    date: '2024.03',
                    place: 'Lotte World Tower, Korea',
                    category: 'Branding'
                }
            ]
        }
    ]
];

// Portfolio List 콘텐츠 (Branding)
export const portfolioListContentBranding = [
    [
        {
            id: 2,
            img: portfolioListLong02,
            title: 'CBS 체이즌 투어',
            desc: 'Columbia Business School 초청 행사 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LOTTE Corporation',
            date: '2024.03',
            place: 'Lotte World Tower, Korea',
            category: 'Branding',
            images: portfolio2,
            others: [
                {
                    id: 3,
                    img: portfolioOther0201,
                    title: '대한항공 신 엔진정비공장<br/> 기공식',
                    desc: '기공식 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2024.03',
                    place: 'Incheon, Korea',
                    category: 'Branding'
                },
                {
                    id: 5,
                    img: portfolioOther0202,
                    title: '2023 뷰티플 비전 콘서트',
                    desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '(사)뷰티플마인드',
                    date: '2023.12',
                    place: 'Grand Hyatt Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 3,
            img: portfolioList03,
            title: '대한항공 신 엔진정비공장<br/> 기공식',
            desc: '기공식 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2024.03',
            place: 'Incheon, Korea',
            category: 'Branding',
            images: portfolio3,
            others: [
                {
                    id: 2,
                    img: portfolioOther0301,
                    title: 'CBS 체이즌 투어',
                    desc: 'Columbia Business School 초청 행사 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LOTTE Corporation',
                    date: '2024.03',
                    place: 'Lotte World Tower, Korea',
                    category: 'Branding'
                },
                {
                    id: 5,
                    img: portfolioOther0302,
                    title: '2023 뷰티플 비전 콘서트',
                    desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '(사)뷰티플마인드',
                    date: '2023.12',
                    place: 'Grand Hyatt Seoul, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 4,
            img: portfolioList04Small,
            title: 'LG IT Innovation Lounge <br/> CES 2024',
            desc: 'CES 2024 LG 전시 라운지 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG',
            date: '2024.01',
            place: 'Las Vegas, USA',
            category: 'SPACE, BRANDING',
            images: portfolio4,
            others: [
                {
                    id: 19,
                    img: portfolioOther0401,
                    title: 'Hannover Messe 2023 <br/> LS Electric',
                    desc: '세계 최대 규모의 산업 전시회 Hannover Messe 2023 전시기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS ELECTRIC',
                    date: '2023.04',
                    place: 'Hannover Fairgrounds, Germany',
                    category: 'Space'
                },
                {
                    id: 22,
                    img: portfolioOther0402,
                    title: 'LS Pavilion InterBattery 2023',
                    desc: '국내 최대 규모의 배터리 전시회 InterBattery 2023 LS Pavilion관 전시기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS Partnership',
                    date: '2023.03',
                    place: 'COEX A/B HALL, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 5,
            img: portfolioList05Long,
            title: '2023 뷰티플 비전 콘서트',
            desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: '(사)뷰티플마인드',
            date: '2023.12',
            place: 'Grand Hyatt Seoul, Korea',
            category: 'Branding',
            images: portfolio5,
            others: [
                {
                    id: 3,
                    img: portfolioOther0501,
                    title: '대한항공 신 엔진정비공장<br/> 기공식',
                    desc: '기공식 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2024.03',
                    place: 'Incheon, Korea',
                    category: 'Branding'
                },
                {
                    id: 8,
                    img: portfolioOther0502,
                    title: 'KE-DL JV 5th <br/> ANNIVERSARY PROMOTION',
                    desc: '대한항공-델타항공 조인트벤처 5주년 기념 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2023.08',
                    place: 'Seoul Times Square, Korea',
                    category: 'Branding, Space'
                }
            ]
        }
    ],
    [
        {
            id: 8,
            img: portfolioList08,
            title: 'KE-DL JV 5th <br/> ANNIVERSARY PROMOTION',
            desc: '대한항공-델타항공 조인트벤처 5주년 기념 전시',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2023.08',
            place: 'Seoul Times Square, Korea',
            category: 'Branding, Space',
            images: portfolio8,
            others: [
                {
                    id: 5,
                    img: portfolioOther0801,
                    title: '2023 뷰티플 비전 콘서트',
                    desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '(사)뷰티플마인드',
                    date: '2023.12',
                    place: 'Grand Hyatt Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 16,
                    img: portfolioOther0802,
                    title: '2023부산세계박람회 <br/> 대한항공 특별기 공개행사',
                    desc: '2030부산세계박람회 성공유치 기원 대한항공의 특별기 래핑 유치홍보 공개 행사',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2023.05',
                    place: 'Incheon International Airport, Korea',
                    category: 'Branding, Public'
                }
            ]
        },
        {
            id: 16,
            img: portfolioList16Small,
            title: '2023부산세계박람회 <br/> 대한항공 특별기 공개행사',
            desc: '2030부산세계박람회 성공유치 기원 대한항공의 특별기 래핑 유치홍보 공개 행사',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2023.05',
            place: 'Incheon International Airport, Korea',
            category: 'Branding, Public',
            images: portfolio16,
            others: [
                {
                    id: 8,
                    img: portfolioOther1601,
                    title: 'KE-DL JV 5th <br/> ANNIVERSARY PROMOTION',
                    desc: '대한항공-델타항공 조인트벤처 5주년 기념 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2023.08',
                    place: 'Seoul Times Square, Korea',
                    category: 'Branding, Space'
                },
                {
                    id: 30,
                    img: portfolioOther1602,
                    title: '한화그룹 LIFEPLUS <br/> RUNNING TRIBE',
                    desc: '러닝 어드바이저 및 런트립 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화그룹',
                    date: '2022.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 23,
            img: portfolioList23Long,
            title: '2023 AHR EXPO LG C&M Exhibition',
            desc: 'LG C&M 전시 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG C&M',
            date: '2023.02',
            place: 'Georgia World Congress Center, USA',
            category: 'Space, Branding',
            images: portfolio23,
            others: [
                {
                    id: 22,
                    img: portfolioOther2301,
                    title: 'LS Pavilion InterBattery 2023',
                    desc: 'InterBattery 전시 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS Partnership',
                    date: '2023.03',
                    place: 'COEX A/B HALL, Korea',
                    category: 'Space'
                },
                {
                    id: 24,
                    img: portfolioOther2302,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                }
            ]
        }
    ],
    [
        {
            id: 24,
            img: portfolioList24,
            title: 'LG IT Innovation Lounge CES 2023',
            desc: 'CES 2023 LG 전시 라운지 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG',
            date: '2023.01',
            place: 'Encore at Wynn Las Vegas, USA',
            category: 'Space, Branding',
            images: portfolio24,
            others: [
                {
                    id: 23,
                    img: portfolioOther2401,
                    title: '2023 AHR EXPO LG C&M Exhibition',
                    desc: 'LG C&M 전시 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG C&M',
                    date: '2023.02',
                    place: 'Georgia World Congress Center, USA',
                    category: 'Space'
                },
                {
                    id: 29,
                    img: portfolioOther2402,
                    title: 'ISC 2022 <br/> (Innodep Solution <br/> Conference 2022)',
                    desc: 'ISC 2022 컨퍼런스 전시 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2022.10',
                    place: '63 Building Seoul, Korea',
                    category: 'Space'
                }
            ]
        },
        {
            id: 30,
            img: portfolioList30,
            title: '한화그룹 LIFEPLUS <br/> RUNNING TRIBE',
            desc: '러닝 어드바이저 및 런트립 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: '한화그룹',
            date: '2022.10',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio30,
            others: [
                {
                    id: 33,
                    img: portfolioOther3001,
                    title: '2030부산세계박람회 <br/> 22년도 유치활동 종합용역',
                    desc: '제170차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 31,
                    img: portfolioOther3002,
                    title: 'Lamborghini Huracan <br/> Tecnica Launch',
                    desc: '람보르기니 우라칸 테크니카 런칭쇼',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2022.07',
                    place: 'SJ KUNSTHALLE Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 31,
            img: portfolioList31,
            title: 'Lamborghini Huracan <br/> Tecnica Launch',
            desc: '람보르기니 우라칸 테크니카 런칭쇼',
            desc2: '기획, 연출 및 제작',
            client: 'Lamborghini',
            date: '2022.07',
            place: 'SJ KUNSTHALLE Seoul, Korea',
            category: 'Branding',
            images: portfolio31,
            others: [
                {
                    id: 30,
                    img: portfolioOther3101,
                    title: '한화그룹 LIFEPLUS <br/> RUNNING TRIBE',
                    desc: '러닝 어드바이저 및 런트립 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화그룹',
                    date: '2022.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 34,
                    img: portfolioOther3102,
                    title: '故일우 조양호 회장 추모사진전',
                    desc: '대한항공 추모사진전 제막식 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2022.06',
                    place: '서울 대한항공 서소문 사옥, Korea',
                    category: 'Branding, Space'
                }
            ]
        }
    ],
    [
        {
            id: 34,
            img: portfolioList34Small,
            title: '故일우 조양호 회장 추모사진전',
            desc: '대한항공 추모사진전 제막식 및 전시',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2022.06',
            place: '서울 대한항공 서소문 사옥, Korea',
            category: 'Branding, Space',
            images: portfolio34,
            others: [
                {
                    id: 31,
                    img: portfolioOther3401,
                    title: 'Lamborghini Huracan <br/> Tecnica Launch',
                    desc: '람보르기니 우라칸 테크니카 런칭쇼',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2022.07',
                    place: 'SJ KUNSTHALLE Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 35,
                    img: portfolioOther3402,
                    title: 'Lamborghini Seoul <br/> Year End Party',
                    desc: '람보르기니 이어앤드 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2021.12',
                    place: 'Lamborghini Seoul Showroom, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 35,
            img: portfolioList35,
            title: 'Lamborghini Seoul <br/> Year End Party',
            desc: '람보르기니 이어앤드 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: 'Lamborghini',
            date: '2021.12',
            place: 'Lamborghini Seoul Showroom, Korea',
            category: 'Branding',
            images: portfolio35,
            others: [
                {
                    id: 34,
                    img: portfolioOther3501,
                    title: '故일우 조양호 회장 추모사진전',
                    desc: '대한항공 추모사진전 제막식 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2022.06',
                    place: '서울 대한항공 서소문 사옥, Korea',
                    category: 'Branding, Space'
                },
                {
                    id: 37,
                    img: portfolioOther3502,
                    title: '애기봉 평화생태공원 개관 기념행사',
                    desc: '개관식 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: '김포 문화 재단',
                    date: '2021.10',
                    place: 'Aegibong Peace ecoPark, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 36,
            img: portfolioList36Long,
            title: '포항 환호공원 <br/> 스페이스 워크 제막식',
            desc: '포항 환호공원 스페이스 워크 제막식 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: 'POSCO',
            date: '2021.11',
            place: 'Pohang, Korea',
            category: 'Space, Branding',
            images: portfolio36,
            others: [
                {
                    id: 29,
                    img: portfolioOther3601,
                    title: 'ISC 2022 <br/> (Innodep Solution <br/> Conference 2022)',
                    desc: 'ISC 2022 컨퍼런스 전시 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2022.10',
                    place: '63 Building Seoul, Korea',
                    category: 'Space'
                },
                {
                    id: 39,
                    img: portfolioOther3602,
                    title: '포스코 수소모빌리티+쇼',
                    desc: '2021 포스코 수소모빌리티 쇼 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.09',
                    place: 'Kintex Hall, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 37,
            img: portfolioList37Small,
            title: '애기봉 평화생태공원 개관 기념행사',
            desc: '개관식 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: '김포 문화 재단',
            date: '2021.10',
            place: 'Aegibong Peace ecoPark, Korea',
            category: 'Branding',
            images: portfolio37,
            others: [
                {
                    id: 35,
                    img: portfolioOther3701,
                    title: 'Lamborghini Seoul <br/> Year End Party',
                    desc: '람보르기니 이어앤드 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2021.12',
                    place: 'Lamborghini Seoul Showroom, Korea',
                    category: 'Branding'
                },
                {
                    id: 40,
                    img: portfolioOther3702,
                    title: '한화생명 LIFE ADIVISOR <br/> Campaign',
                    desc: 'SPACE, RUNNING, SECOND LIFE 갬페인 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화생명',
                    date: '2021.08',
                    place: 'Seoul, Jeju, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 40,
            img: portfolioList40Small,
            title: '한화생명 LIFE ADIVISOR <br/> Campaign',
            desc: 'SPACE, RUNNING, SECOND LIFE 갬페인 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: '한화생명',
            date: '2021.08',
            place: 'Seoul, Jeju, Korea',
            category: 'Branding',
            images: portfolio40,
            others: [
                {
                    id: 37,
                    img: portfolioOther4001,
                    title: '애기봉 평화생태공원 개관 기념행사',
                    desc: '개관식 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: '김포 문화 재단',
                    date: '2021.10',
                    place: 'Aegibong Peace ecoPark, Korea',
                    category: 'Branding'
                },
                {
                    id: 41,
                    img: portfolioOther4002,
                    title: '교보생명 Vision 2025 선포식',
                    desc: 'Vision2025 선포식 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '교보생명',
                    date: '2021.04',
                    place: 'Kyobo Building Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 41,
            img: portfolioList41Long,
            title: '교보생명 Vision 2025 선포식',
            desc: 'Vision2025 선포식 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: '교보생명',
            date: '2021.04',
            place: 'Kyobo Building Korea',
            category: 'Branding',
            images: portfolio41,
            others: [
                {
                    id: 40,
                    img: portfolioOther4101,
                    title: '한화생명 LIFE ADIVISOR <br/> Campaign',
                    desc: 'SPACE, RUNNING, SECOND LIFE 갬페인 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화생명',
                    date: '2021.08',
                    place: 'Seoul, Jeju, Korea',
                    category: 'Branding'
                },
                {
                    id: 42,
                    img: portfolioOther4102,
                    title: 'SK Telecom Galaxy A <br/> QUANTUM2 Viral Event',
                    desc: '갤럭시A 퀸텀2 한정 바이럴 유튜브 콘텐츠 제작',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2021.04',
                    place: 'Gwangju National Science Museum, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 42,
            img: portfolioList42,
            title: 'SK Telecom Galaxy A <br/> QUANTUM2 Viral Event',
            desc: '갤럭시A 퀸텀2 한정 바이럴 유튜브 콘텐츠 제작',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2021.04',
            place: 'Gwangju National Science Museum, Korea',
            category: 'Branding',
            images: portfolio42,
            others: [
                {
                    id: 41,
                    img: portfolioOther4201,
                    title: '교보생명 Vision 2025 선포식',
                    desc: 'Vision2025 선포식 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '교보생명',
                    date: '2021.04',
                    place: 'Kyobo Building Korea',
                    category: 'Branding'
                },
                {
                    id: 43,
                    img: portfolioOther4202,
                    // eslint-disable-next-line quotes
                    title: "SK Telecom 수고0페 '0원한 응원전'",
                    desc: '수능 계기 프로모션 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.12',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 43,
            img: portfolioList43Small,
            // eslint-disable-next-line quotes
            title: "SK Telecom 수고0페 '0원한 응원전'",
            desc: '수능 계기 프로모션 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2020.12',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio43,
            others: [
                {
                    id: 42,
                    img: portfolioOther4301,
                    title: 'SK Telecom Galaxy A <br/> QUANTUM2 Viral Event',
                    desc: '갤럭시A 퀸텀2 한정 바이럴 유튜브 콘텐츠 제작',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2021.04',
                    place: 'Gwangju National Science Museum, Korea',
                    category: 'Branding'
                },
                {
                    id: 44,
                    img: portfolioOther4302,
                    title: 'SK Telecom iPhone12 <br/> Launching Party',
                    desc: '아이폰12 런칭파티 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 44,
            img: portfolioList44Long,
            title: 'SK Telecom iPhone12 <br/> Launching Party',
            desc: '아이폰12 런칭파티 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2020.10',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio44,
            others: [
                {
                    id: 43,
                    img: portfolioOther4401,
                    // eslint-disable-next-line quotes
                    title: "SK Telecom 수고0페 '0원한 응원전'",
                    desc: '수능 계기 프로모션 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.12',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 46,
                    img: portfolioOther4402,
                    title: 'SK Telecom 플래그십 스토어 <br/> T Factory Launching',
                    desc: '온라인 기자간담회 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.08',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 46,
            img: portfolioList46Small,
            title: 'SK Telecom 플래그십 스토어 <br/> T Factory Launching',
            desc: '온라인 기자간담회 운영',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2020.08',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio46,
            others: [
                {
                    id: 44,
                    img: portfolioOther4601,
                    title: 'SK Telecom iPhone12 <br/> Launching Party',
                    desc: '아이폰12 런칭파티 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 47,
                    img: portfolioOther4602,
                    title: 'SK Telecom Galaxy Note20 <br/> Launching Showcase',
                    desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.08',
                    place: 'Bitplex, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 47,
            img: portfolioList47,
            title: 'SK Telecom Galaxy Note20 <br/> Launching Showcase',
            desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2020.08',
            place: 'Bitplex, Korea',
            category: 'Branding',
            images: portfolio47,
            others: [
                {
                    id: 46,
                    img: portfolioOther4701,
                    title: 'SK Telecom 플래그십 스토어 <br/> T Factory Launching',
                    desc: '온라인 기자간담회 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.08',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 49,
                    img: portfolioOther4702,
                    // eslint-disable-next-line quotes
                    title: "한진 CSV 프로젝트 '식탁위수박'",
                    desc: '함안수박 팝업스토어 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '한진',
                    date: '2020.05',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 49,
            img: portfolioList49,
            // eslint-disable-next-line quotes
            title: "한진 CSV 프로젝트 '식탁위수박'",
            desc: '함안수박 팝업스토어 기획 및 제작',
            desc2: '기획, 연출 및 제작',
            client: '한진',
            date: '2020.05',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio49,
            others: [
                {
                    id: 47,
                    img: portfolioOther4901,
                    title: 'SK Telecom Galaxy Note20 <br/> Launching Showcase',
                    desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2020.08',
                    place: 'Bitplex, Korea',
                    category: 'Branding'
                },
                {
                    id: 51,
                    img: portfolioOther4902,
                    title: '파라다이스 카지노 <br/> 중국 VIP 춘절 디너쇼',
                    desc: '파라다이스 카지노 중국 VIP 춘절 디너쇼 기획 연출 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '파라다이스',
                    date: '2020.01',
                    place: 'Grand Walkerhill Seoul, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 51,
            img: portfolioList51,
            title: '파라다이스 카지노 <br/> 중국 VIP 춘절 디너쇼',
            desc: '파라다이스 카지노 중국 VIP 춘절 디너쇼 기획 연출 및 제작',
            desc2: '기획, 연출 및 제작',
            client: '파라다이스',
            date: '2020.01',
            place: 'Grand Walkerhill Seoul, Korea',
            category: 'Branding',
            images: portfolio51,
            others: [
                {
                    id: 49,
                    img: portfolioOther5101,
                    // eslint-disable-next-line quotes
                    title: "한진 CSV 프로젝트 '식탁위수박'",
                    desc: '함안수박 팝업스토어 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '한진',
                    date: '2020.05',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 52,
                    img: portfolioOther5102,
                    title: '파라다이스 시티 <br/> 2020 COUNT DOWN',
                    desc: '카지노 신년맞이 공연 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: '파라다이스',
                    date: '2019.12',
                    place: 'Incheon, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 52,
            img: portfolioList52Small,
            title: '파라다이스 시티 <br/> 2020 COUNT DOWN',
            desc: '카지노 신년맞이 공연 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: '파라다이스',
            date: '2019.12',
            place: 'Incheon, Korea',
            category: 'Branding',
            images: portfolio52,
            others: [
                {
                    id: 51,
                    img: portfolioOther5201,
                    title: '파라다이스 카지노 <br/> 중국 VIP 춘절 디너쇼',
                    desc: '파라다이스 카지노 중국 VIP 춘절 디너쇼 기획 연출 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '파라다이스',
                    date: '2020.01',
                    place: 'Grand Walkerhill Seoul, Korea',
                    category: 'Space'
                },
                {
                    id: 53,
                    img: portfolioOther5202,
                    title: 'SK Telecom 캐럴 프로젝트',
                    desc: '캐럴 프로젝트 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2019.12',
                    place: 'Seoul, Busan, Gwangju, Daejeon, Daegu, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 53,
            img: portfolioList53Long,
            title: 'SK Telecom 캐럴 프로젝트',
            desc: '캐럴 프로젝트 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: 'SK Telecom',
            date: '2019.12',
            place: 'Seoul, Busan, Gwangju, Daejeon, Daegu, Korea',
            category: 'Branding',
            images: portfolio53,
            others: [
                {
                    id: 52,
                    img: portfolioOther5301,
                    title: '파라다이스 시티 <br/> 2020 COUNT DOWN',
                    desc: '카지노 신년맞이 공연 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: '파라다이스',
                    date: '2019.12',
                    place: 'Incheon, Korea',
                    category: 'Branding'
                },
                {
                    id: 54,
                    img: portfolioOther5302,
                    title: '파라다이스 카지노 워커힐 <br/> 국제 송년 디너쇼',
                    desc: '국제 VIP 대상 공연 기획 및 연출',
                    desc2: '',
                    client: '파라다이스',
                    date: '2019.06',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 54,
            img: portfolioList54,
            title: '파라다이스 카지노 워커힐 <br/> 국제 송년 디너쇼',
            desc: '국제 VIP 대상 공연 기획 및 연출',
            desc2: '',
            client: '파라다이스',
            date: '2019.06',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio54,
            others: [
                {
                    id: 53,
                    img: portfolioOther5401,
                    title: 'SK Telecom 캐럴 프로젝트',
                    desc: '캐럴 프로젝트 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'SK Telecom',
                    date: '2019.12',
                    place: 'Seoul, Busan, Gwangju, Daejeon, Daegu, Korea',
                    category: 'Branding'
                },
                {
                    id: 57,
                    img: portfolioOther5402,
                    title: '파라다이스 카지노 워커힐 송이파티',
                    desc: '일본 VIP 대상 공연 기획 및 연출',
                    desc2: '',
                    client: '파라다이스',
                    date: '2019.09',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 57,
            img: portfolioList57,
            title: '파라다이스 카지노 워커힐 송이파티',
            desc: '일본 VIP 대상 공연 기획 및 연출',
            desc2: '',
            client: '파라다이스',
            date: '2019.09',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio57,
            others: [
                {
                    id: 54,
                    img: portfolioOther5701,
                    title: '파라다이스 카지노 워커힐 <br/> 국제 송년 디너쇼',
                    desc: '국제 VIP 대상 공연 기획 및 연출',
                    desc2: '',
                    client: '파라다이스',
                    date: '2019.06',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 58,
                    img: portfolioOther5702,
                    title: '한진 농가소득지원 프로젝트',
                    desc: '모바일 판로개척 및 서포터즈 운영',
                    desc2: '',
                    client: '한진',
                    date: '2019.08',
                    place: 'Haman, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 58,
            img: portfolioList58,
            title: '한진 농가소득지원 프로젝트',
            desc: '모바일 판로개척 및 서포터즈 운영',
            desc2: '',
            client: '한진',
            date: '2019.08',
            place: 'Haman, Korea',
            category: 'Branding',
            images: portfolio58,
            others: [
                {
                    id: 57,
                    img: portfolioOther5801,
                    title: '파라다이스 카지노 워커힐 송이파티',
                    desc: '일본 VIP 대상 공연 기획 및 연출',
                    desc2: '',
                    client: '파라다이스',
                    date: '2019.09',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 59,
                    img: portfolioOther5802,
                    title: 'SK Telecom 5GX <br/> Summer Festival',
                    desc: '콜비지 프로모션 기획 및 운영',
                    desc2: '',
                    client: 'SK Telecom',
                    date: '2019.08',
                    place: 'Busan, GangWon, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 59,
            img: portfolioList59,
            title: 'SK Telecom 5GX <br/> Summer Festival',
            desc: '콜비지 프로모션 기획 및 운영',
            desc2: '',
            client: 'SK Telecom',
            date: '2019.08',
            place: 'Busan, GangWon, Korea',
            category: 'Branding',
            images: portfolio59,
            others: [
                {
                    id: 58,
                    img: portfolioOther5901,
                    title: '한진 농가소득지원 프로젝트',
                    desc: '모바일 판로개척 및 서포터즈 운영',
                    desc2: '',
                    client: '한진',
                    date: '2019.08',
                    place: 'Haman, Korea',
                    category: 'Branding'
                },
                {
                    id: 61,
                    img: portfolioOther5902,
                    title: '교보생명 2019 고객보장대상',
                    desc: '고객보장대상 총괄 기획 및 운영',
                    desc2: '',
                    client: '교보생명',
                    date: '2019.04',
                    place: '천안 교보생명 계성원, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 61,
            img: portfolioList61Small,
            title: '교보생명 2019 고객보장대상',
            desc: '고객보장대상 총괄 기획 및 운영',
            desc2: '',
            client: '교보생명',
            date: '2019.04',
            place: '천안 교보생명 계성원, Korea',
            category: 'Branding',
            images: portfolio61,
            others: [
                {
                    id: 59,
                    img: portfolioOther6101,
                    title: 'SK Telecom 5GX <br/> Summer Festival',
                    desc: '콜비지 프로모션 기획 및 운영',
                    desc2: '',
                    client: 'SK Telecom',
                    date: '2019.08',
                    place: 'Busan, GangWon, Korea',
                    category: 'Branding'
                },
                {
                    id: 62,
                    img: portfolioOther6102,
                    title: '대한항공 50년사 편찬 기념식',
                    desc: '대한항공 50년사 편찬 기념식 기획 및 운영',
                    desc2: '',
                    client: '대한항공',
                    date: '2019.03',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        }
    ],
    [
        {
            id: 62,
            img: portfolioList62,
            title: '대한항공 50년사 편찬 기념식',
            desc: '대한항공 50년사 편찬 기념식 기획 및 운영',
            desc2: '',
            client: '대한항공',
            date: '2019.03',
            place: 'Seoul, Korea',
            category: 'Branding',
            images: portfolio62,
            others: [
                {
                    id: 61,
                    img: portfolioOther6201,
                    title: '교보생명 2019 고객보장대상',
                    desc: '고객보장대상 총괄 기획 및 운영',
                    desc2: '',
                    client: '교보생명',
                    date: '2019.04',
                    place: '천안 교보생명 계성원, Korea',
                    category: 'Branding'
                },
                {
                    id: 2,
                    img: portfolioOther6202,
                    title: 'CBS 체이즌 투어',
                    desc: 'Columbia Business School 초청 행사 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LOTTE Corporation',
                    date: '2024.03',
                    place: 'Lotte World Tower, Korea',
                    category: 'Branding'
                }
            ]
        }
    ]
];

// Portfolio List 콘텐츠 (Space)
export const portfolioListContentSpace = [
    [
        {
            id: 4,
            img: portfolioList04,
            title: 'LG IT Innovation Lounge <br/> CES 2024',
            desc: 'CES 2024 LG 전시 라운지 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG',
            date: '2024.01',
            place: 'Las Vegas, USA',
            category: 'SPACE, BRANDING',
            images: portfolio4,
            others: [
                {
                    id: 19,
                    img: portfolioOther0401,
                    title: 'Hannover Messe 2023 <br/> LS Electric',
                    desc: '세계 최대 규모의 산업 전시회 Hannover Messe 2023 전시기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS ELECTRIC',
                    date: '2023.04',
                    place: 'Hannover Fairgrounds, Germany',
                    category: 'Space'
                },
                {
                    id: 22,
                    img: portfolioOther0402,
                    title: 'LS Pavilion InterBattery 2023',
                    desc: '국내 최대 규모의 배터리 전시회 InterBattery 2023 LS Pavilion관 전시기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS Partnership',
                    date: '2023.03',
                    place: 'COEX A/B HALL, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 8,
            img: portfolioList08,
            title: 'KE-DL JV 5th <br/> ANNIVERSARY PROMOTION',
            desc: '대한항공-델타항공 조인트벤처 5주년 기념 전시',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2023.08',
            place: 'Seoul Times Square, Korea',
            category: 'Branding, Space',
            images: portfolio8,
            others: [
                {
                    id: 5,
                    img: portfolioOther0801,
                    title: '2023 뷰티플 비전 콘서트',
                    desc: '뷰티플마인드 연말 기부 콘서트 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '(사)뷰티플마인드',
                    date: '2023.12',
                    place: 'Grand Hyatt Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 16,
                    img: portfolioOther0802,
                    title: '2023부산세계박람회 <br/> 대한항공 특별기 공개행사',
                    desc: '2030부산세계박람회 성공유치 기원 대한항공의 특별기 래핑 유치홍보 공개 행사',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2023.05',
                    place: 'Incheon International Airport, Korea',
                    category: 'Branding, Public'
                }
            ]
        },
        {
            id: 17,
            img: portfolioList17,
            // eslint-disable-next-line quotes
            title: "주프랑스한국문화원 특별기획전 <br/> '엑스포 오마주 1900'",
            desc: '2030부산세계박람회 홍보관 전시',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.05',
            place: 'Paris, France',
            category: 'Public, Space',
            images: portfolio17,
            others: [
                {
                    id: 15,
                    img: portfolioOther1701,
                    title: "2030부산세계박람회 유치홍보 <br/> '제834회 함부르크 개항축제'",
                    desc: "2030부산세계박람회 유치홍보 '제834회 함부르크 개항축제' 홍보부스 운영",
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Hamburg, Germany',
                    category: 'Public'
                },
                {
                    id: 18,
                    img: portfolioOther1702,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '엑스포 해양관'",
                    desc: '부산엑스포 유치기원 캠페인 아쿠아리움 전시 기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lotte World Aquarium',
                    date: '2023.05',
                    place: 'Busan, Korea',
                    category: 'Public, Space'
                }
            ]
        }
    ],
    [
        {
            id: 18,
            img: portfolioList18Long,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 캠페인 <br/> '엑스포 해양관'",
            desc: '부산엑스포 유치기원 캠페인 아쿠아리움 전시 기획',
            desc2: '기획, 연출 및 제작',
            client: 'Lotte World Aquarium',
            date: '2023.05',
            place: 'Busan, Korea',
            category: 'Public, Space',
            images: portfolio18,
            others: [
                {
                    id: 17,
                    img: portfolioOther1801,
                    // eslint-disable-next-line quotes
                    title: "주프랑스한국문화원 특별기획전 <br/> '엑스포 오마주 1900'",
                    desc: '2030부산세계박람회 홍보관 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.05',
                    place: 'Paris, France',
                    category: 'Public, Space'
                },
                {
                    id: 20,
                    img: portfolioOther1802,
                    title: '2030부산세계박람회 BIE <br/> 현지실사대응',
                    desc: '2030부산세계박람회 유치활동 BIE 실사단 방문 총괄',
                    desc2: '기획, 연출 및 제작',
                    client: '산업통상자원부',
                    date: '2023.04',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 19,
            img: portfolioList19Small,
            title: 'Hannover Messe 2023 <br/> LS Electric',
            desc: '세계 최대 규모의 산업 전시회 Hannover Messe 2023 전시기획',
            desc2: '기획, 연출 및 제작',
            client: 'LS ELECTRIC',
            date: '2023.04',
            place: 'Hannover Fairgrounds, Germany',
            category: 'Space',
            images: portfolio19,
            others: [
                {
                    id: 24,
                    img: portfolioOther1901,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                },
                {
                    id: 22,
                    img: portfolioOther1902,
                    title: 'LS Pavilion InterBattery 2023',
                    desc: 'InterBattery 전시 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS Partnership',
                    date: '2023.03',
                    place: 'COEX A/B HALL, Korea',
                    category: 'Space'
                }
            ]
        },
        {
            id: 22,
            img: portfolioList22Small,
            title: 'LS Pavilion InterBattery 2023',
            desc: 'InterBattery 전시 기획 및 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LS Partnership',
            date: '2023.03',
            place: 'COEX A/B HALL, Korea',
            category: 'Space',
            images: portfolio22,
            others: [
                {
                    id: 19,
                    img: portfolioOther2201,
                    title: 'Hannover Messe 2023 <br/> LS Electric',
                    desc: '세계 최대 규모의 산업 전시회 Hannover Messe 2023 전시기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS ELECTRIC',
                    date: '2023.04',
                    place: 'Hannover Fairgrounds, Germany',
                    category: 'Space'
                },
                {
                    id: 23,
                    img: portfolioOther2202,
                    title: '2023 AHR EXPO LG C&M Exhibition',
                    desc: 'LG C&M 전시 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG C&M',
                    date: '2023.02',
                    place: 'Georgia World Congress Center, USA',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 23,
            img: portfolioList23Long,
            title: '2023 AHR EXPO LG C&M Exhibition',
            desc: 'LG C&M 전시 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG C&M',
            date: '2023.02',
            place: 'Georgia World Congress Center, USA',
            category: 'Space, Branding',
            images: portfolio23,
            others: [
                {
                    id: 22,
                    img: portfolioOther2301,
                    title: 'LS Pavilion InterBattery 2023',
                    desc: 'InterBattery 전시 기획 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LS Partnership',
                    date: '2023.03',
                    place: 'COEX A/B HALL, Korea',
                    category: 'Space'
                },
                {
                    id: 24,
                    img: portfolioOther2302,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                }
            ]
        }
    ],
    [
        {
            id: 24,
            img: portfolioList24,
            title: 'LG IT Innovation Lounge CES 2023',
            desc: 'CES 2023 LG 전시 라운지 운영',
            desc2: '기획, 연출 및 제작',
            client: 'LG',
            date: '2023.01',
            place: 'Encore at Wynn Las Vegas, USA',
            category: 'Space, Branding',
            images: portfolio24,
            others: [
                {
                    id: 23,
                    img: portfolioOther2401,
                    title: '2023 AHR EXPO LG C&M Exhibition',
                    desc: 'LG C&M 전시 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG C&M',
                    date: '2023.02',
                    place: 'Georgia World Congress Center, USA',
                    category: 'Space'
                },
                {
                    id: 29,
                    img: portfolioOther2402,
                    title: 'ISC 2022 <br/> (Innodep Solution <br/> Conference 2022)',
                    desc: 'ISC 2022 컨퍼런스 전시 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2022.10',
                    place: '63 Building Seoul, Korea',
                    category: 'Space'
                }
            ]
        },
        {
            id: 28,
            img: portfolioList28Small,
            title: '2030부산세계박람회 <br/> X4 SHOWCASE',
            desc: '홍보대사 팬사인회',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2022.10',
            place: 'Lotte World Seoul, Korea',
            category: 'Public, Space',
            images: portfolio28,
            others: [
                {
                    id: 27,
                    img: portfolioOther2801,
                    title: '제15회 마포나루 새우젓축제',
                    desc: '축제 총괄 기획 및 연출 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '마포구',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 32,
                    img: portfolioOther2802,
                    title: '2022 해외한국문화축제',
                    desc: '전세계 대상 한류 콘텐츠 문화행사 기획',
                    desc2: '기획, 연출 및 제작',
                    client: '문화체육관광부',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 29,
            img: portfolioList29Long,
            title: 'ISC 2022 <br/> (Innodep Solution <br/> Conference 2022)',
            desc: 'ISC 2022 컨퍼런스 전시 및 운영',
            desc2: '기획, 연출 및 제작',
            client: 'Innodep',
            date: '2022.10',
            place: '63 Building Seoul, Korea',
            category: 'Space',
            images: portfolio29,
            others: [
                {
                    id: 24,
                    img: portfolioOther2901,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                },
                {
                    id: 36,
                    img: portfolioOther2902,
                    title: '포항 환호공원 <br/> 스페이스 워크 제막식',
                    desc: '포항 환호공원 스페이스 워크 제막식 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.11',
                    place: 'Pohang, Korea',
                    category: 'Space, Branding'
                }
            ]
        }
    ],
    [
        {
            id: 34,
            img: portfolioList34Small,
            title: '故일우 조양호 회장 추모사진전',
            desc: '대한항공 추모사진전 제막식 및 전시',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2022.06',
            place: '서울 대한항공 서소문 사옥, Korea',
            category: 'Branding, Space',
            images: portfolio34,
            others: [
                {
                    id: 31,
                    img: portfolioOther3401,
                    title: 'Lamborghini Huracan <br/> Tecnica Launch',
                    desc: '람보르기니 우라칸 테크니카 런칭쇼',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2022.07',
                    place: 'SJ KUNSTHALLE Seoul, Korea',
                    category: 'Branding'
                },
                {
                    id: 35,
                    img: portfolioOther3402,
                    title: 'Lamborghini Seoul <br/> Year End Party',
                    desc: '람보르기니 이어앤드 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lamborghini',
                    date: '2021.12',
                    place: 'Lamborghini Seoul Showroom, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 36,
            img: portfolioList36,
            title: '포항 환호공원 <br/> 스페이스 워크 제막식',
            desc: '포항 환호공원 스페이스 워크 제막식 기획 및 연출',
            desc2: '기획, 연출 및 제작',
            client: 'POSCO',
            date: '2021.11',
            place: 'Pohang, Korea',
            category: 'Space, Branding',
            images: portfolio36,
            others: [
                {
                    id: 29,
                    img: portfolioOther3601,
                    title: 'ISC 2022 <br/> (Innodep Solution <br/> Conference 2022)',
                    desc: 'ISC 2022 컨퍼런스 전시 및 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2022.10',
                    place: '63 Building Seoul, Korea',
                    category: 'Space'
                },
                {
                    id: 39,
                    img: portfolioOther3602,
                    title: '포스코 수소모빌리티+쇼',
                    desc: '2021 포스코 수소모빌리티 쇼 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.09',
                    place: 'Kintex Hall, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 39,
            img: portfolioList39Long,
            title: '포스코 수소모빌리티+쇼',
            desc: '2021 포스코 수소모빌리티 쇼 전시',
            desc2: '기획, 연출 및 제작',
            client: 'POSCO',
            date: '2021.09',
            place: 'Kintex Hall, Korea',
            category: 'Space',
            images: portfolio39,
            others: [
                {
                    id: 36,
                    img: portfolioOther3901,
                    title: '포항 환호공원 <br/> 스페이스 워크 제막식',
                    desc: '포항 환호공원 스페이스 워크 제막식 기획 및 연출',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.11',
                    place: 'Pohang, Korea',
                    category: 'Space, Branding'
                },
                {
                    id: 45,
                    img: portfolioOther3902,
                    title: 'ISC 2020 Smartcity <br/> e-Showference',
                    desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2020.10',
                    place: 'KINTEX, Korea',
                    category: 'Space'
                }
            ]
        }
    ],
    [
        {
            id: 45,
            img: portfolioList45,
            title: 'ISC 2020 Smartcity <br/> e-Showference',
            desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
            desc2: '기획, 연출 및 제작',
            client: 'Innodep',
            date: '2020.10',
            place: 'KINTEX, Korea',
            category: 'Space',
            images: portfolio45,
            others: [
                {
                    id: 39,
                    img: portfolioOther4501,
                    title: '포스코 수소모빌리티+쇼',
                    desc: '2021 포스코 수소모빌리티 쇼 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'POSCO',
                    date: '2021.09',
                    place: 'Kintex Hall, Korea',
                    category: 'Space'
                },
                {
                    id: 50,
                    img: portfolioOther4502,
                    // eslint-disable-next-line quotes
                    title: "2021 여수 미디어아트뮤지엄 <br/> '녹테마레'콘텐츠 기획ㆍ제작",
                    desc: '콘텐츠 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '(주) 신원',
                    date: '2020.03',
                    place: 'Yeosu, Korea',
                    category: 'Space'
                }
            ]
        },
        {
            id: 50,
            img: portfolioList50,
            // eslint-disable-next-line quotes
            title: "2021 여수 미디어아트뮤지엄 <br/> '녹테마레'콘텐츠 기획ㆍ제작",
            desc: '콘텐츠 기획 및 제작',
            desc2: '기획, 연출 및 제작',
            client: '(주) 신원',
            date: '2020.03',
            place: 'Yeosu, Korea',
            category: 'Space',
            images: portfolio50,
            others: [
                {
                    id: 45,
                    img: portfolioOther5001,
                    title: 'ISC 2020 Smartcity <br/> e-Showference',
                    desc: 'ISC 2020 Smartcity e-Showference 기획 및 전시',
                    desc2: '기획, 연출 및 제작',
                    client: 'Innodep',
                    date: '2020.10',
                    place: 'KINTEX, Korea',
                    category: 'Space'
                },
                {
                    id: 24,
                    img: portfolioOther5002,
                    title: 'LG IT Innovation Lounge CES 2023',
                    desc: 'CES 2023 LG 전시 라운지 운영',
                    desc2: '기획, 연출 및 제작',
                    client: 'LG',
                    date: '2023.01',
                    place: 'Encore at Wynn Las Vegas, USA',
                    category: 'Space, Branding'
                }
            ]
        }
    ]
];

// Portfolio List 콘텐츠 (Public)
export const portfolioListContentPublic = [
    [
        {
            id: 1,
            img: portfolioList01,
            title: 'GSAT 2024',
            desc: 'GSAT 2024 총괄 대행 운영',
            desc2: '기획, 연출 및 제작',
            client: '경상남도',
            date: '2024.04',
            place: 'Chagwon, Korea',
            category: 'Public',
            images: portfolio1,
            others: [
                {
                    id: 6,
                    img: portfolioOther0101,
                    title: '부산엑스포 국제 심포지움 만찬',
                    desc: '2023부산세계박람회 유치기원 만찬 및 교류 행사',
                    desc2: '기획, 연출 및 제작',
                    client: '대한상공회의소',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 7,
                    img: portfolioOther0102,
                    title: 'MCOUNTDOWN IN FRANCE <br/> VIP Lounge & Skybox',
                    desc: '2030부산세계박람회 유치기원 콘서트 VIP 행사장',
                    desc2: '기획, 연출 및 제작',
                    client: '대통령실 부산시',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 6,
            img: portfolioList06,
            title: '부산엑스포 국제 심포지움 만찬',
            desc: '2023부산세계박람회 유치기원 만찬 및 교류 행사',
            desc2: '기획, 연출 및 제작',
            client: '대한상공회의소',
            date: '2023.10',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio6,
            others: [
                {
                    id: 1,
                    img: portfolioOther0601,
                    title: 'GSAT 2024',
                    desc: 'GSAT 2024 총괄 대행 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '경상남도',
                    date: '2024.04',
                    place: 'Chagwon, Korea',
                    category: 'Public'
                },
                {
                    id: 7,
                    img: portfolioOther0602,
                    title: 'MCOUNTDOWN IN FRANCE <br/> VIP Lounge & Skybox',
                    desc: '2030부산세계박람회 유치기원 콘서트 VIP 행사장',
                    desc2: '기획, 연출 및 제작',
                    client: '대통령실 부산시',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        },
        {
            id: 7,
            img: portfolioList07Small,
            title: 'MCOUNTDOWN IN FRANCE <br/> VIP Lounge & Skybox',
            desc: '2030부산세계박람회 유치기원 콘서트 VIP 행사장',
            desc2: '기획, 연출 및 제작',
            client: '대통령실 부산시',
            date: '2023.10',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio7,
            others: [
                {
                    id: 6,
                    img: portfolioOther0701,
                    title: '부산엑스포 국제 심포지움 만찬',
                    desc: '2023부산세계박람회 유치기원 만찬 및 교류 행사',
                    desc2: '기획, 연출 및 제작',
                    client: '대한상공회의소',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 9,
                    img: portfolioOther0702,
                    title: '2023 OMMAGAANG <br/> (오메강 축제) 연계 홍보',
                    desc: '2030부산세계박람회 홍보부스 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.06',
                    place: 'Brussels, Belgium',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 9,
            img: portfolioList09Long,
            title: '2023 OMMAGAANG <br/> (오메강 축제) 연계 홍보',
            desc: '2030부산세계박람회 홍보부스 운영',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.06',
            place: 'Brussels, Belgium',
            category: 'Public',
            images: portfolio9,
            others: [
                {
                    id: 7,
                    img: portfolioOther0901,
                    title: 'MCOUNTDOWN IN FRANCE <br/> VIP Lounge & Skybox',
                    desc: '2030부산세계박람회 유치기원 콘서트 VIP 행사장',
                    desc2: '기획, 연출 및 제작',
                    client: '대통령실 부산시',
                    date: '2023.10',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 10,
                    img: portfolioOther0902,
                    title: '한미동맹 70주년 기념행사',
                    desc: '한미동맹 70주년 감사 오찬 행사 및 추모의 벽 참배 행사 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '국가보훈처',
                    date: '2023.06',
                    place: 'Washington DC USA',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 10,
            img: portfolioList10Small,
            title: '한미동맹 70주년 기념행사',
            desc: '한미동맹 70주년 감사 오찬 행사 및 추모의 벽 참배 행사 기획 운영',
            desc2: '기획, 연출 및 제작',
            client: '국가보훈처',
            date: '2023.06',
            place: 'Washington DC USA',
            category: 'Public',
            images: portfolio10,
            others: [
                {
                    id: 9,
                    img: portfolioOther1001,
                    title: '2023 OMMAGAANG <br/> (오메강 축제) 연계 홍보',
                    desc: '2030부산세계박람회 홍보부스 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.06',
                    place: 'Brussels, Belgium',
                    category: 'Public'
                },
                {
                    id: 11,
                    img: portfolioOther1002,
                    title: "2030부산세계박람회 <br/> 23년도 유치활동 종합용역 <br/> 'Walking to 2030'",
                    desc: '제172차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        },
        {
            id: 11,
            img: portfolioList11,
            title: "2030부산세계박람회 <br/> 23년도 유치활동 종합용역 <br/> 'Walking to 2030'",
            desc: '제172차 BIE 총회 계기 파리 현지 홍보',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.06',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio11,
            others: [
                {
                    id: 10,
                    img: portfolioOther1101,
                    title: '한미동맹 70주년 기념행사',
                    desc: '한미동맹 70주년 감사 오찬 행사 및 추모의 벽 참배 행사 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '국가보훈처',
                    date: '2023.06',
                    place: 'Washington DC USA',
                    category: 'Public'
                },
                {
                    id: 12,
                    img: portfolioOther1102,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '그린클 챌린지'",
                    desc: '부산엑스포 유치기원 캠페인',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 12,
            img: portfolioList12Long,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 캠페인 <br/> '그린클 챌린지'",
            desc: '부산엑스포 유치기원 캠페인',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2023.06',
            place: 'Seoul, Busan, Korea',
            category: 'Public',
            images: portfolio12,
            others: [
                {
                    id: 11,
                    img: portfolioOther1201,
                    title: "2030부산세계박람회 <br/> 23년도 유치활동 종합용역 <br/> 'Walking to 2030'",
                    desc: '제172차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 13,
                    img: portfolioOther1202,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 유치홍보 in PARIS '부산다방'",
                    desc: '박람회 성공유지를 위한 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 13,
            img: portfolioList13Small,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 <br/> 유치홍보 in PARIS '부산다방'",
            desc: '박람회 성공유지를 위한 파리 현지 홍보',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2023.06',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio13,
            others: [
                {
                    id: 12,
                    img: portfolioOther1301,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '그린클 챌린지'",
                    desc: '부산엑스포 유치기원 캠페인',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                },
                {
                    id: 14,
                    img: portfolioOther1302,
                    title: 'K-Finance Week in <br/> Indonesia 2023',
                    desc: '한국-인도네시아 수교 50주년 기념 한국계 금융사 인도네시아 투자 위크',
                    desc2: '기획, 연출 및 제작',
                    client: '외교부',
                    date: '2023.05',
                    place: 'Jakarta, Indonesia',
                    category: 'Public'
                }
            ]
        },
        {
            id: 14,
            img: portfolioList14,
            title: 'K-Finance Week in <br/> Indonesia 2023',
            desc: '한국-인도네시아 수교 50주년 기념 한국계 금융사 인도네시아 투자 위크',
            desc2: '기획, 연출 및 제작',
            client: '외교부',
            date: '2023.05',
            place: 'Jakarta, Indonesia',
            category: 'Public',
            images: portfolio14,
            others: [
                {
                    id: 13,
                    img: portfolioOther1401,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 유치홍보 in PARIS '부산다방'",
                    desc: '박람회 성공유지를 위한 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 15,
                    img: portfolioOther1402,
                    title: "2030부산세계박람회 유치홍보 <br/> '제834회 함부르크 개항축제'",
                    desc: "2030부산세계박람회 유치홍보 '제834회 함부르크 개항축제' 홍보부스 운영",
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Hamburg, Germany',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 15,
            img: portfolioList15Long,
            title: "2030부산세계박람회 유치홍보 <br/> '제834회 함부르크 개항축제'",
            desc: "2030부산세계박람회 유치홍보 '제834회 함부르크 개항축제' 홍보부스 운영",
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2023.06',
            place: 'Hamburg, Germany',
            category: 'Public',
            images: portfolio15,
            others: [
                {
                    id: 14,
                    img: portfolioOther1501,
                    title: 'K-Finance Week in <br/> Indonesia 2023',
                    desc: '한국-인도네시아 수교 50주년 기념 한국계 금융사 인도네시아 투자 위크',
                    desc2: '기획, 연출 및 제작',
                    client: '외교부',
                    date: '2023.05',
                    place: 'Jakarta, Indonesia',
                    category: 'Public'
                },
                {
                    id: 17,
                    img: portfolioOther1502,
                    // eslint-disable-next-line quotes
                    title: "주프랑스한국문화원 특별기획전 <br/> '엑스포 오마주 1900'",
                    desc: '2030부산세계박람회 홍보관 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.05',
                    place: 'Paris, France',
                    category: 'Public, Space'
                }
            ]
        }
    ],
    [
        {
            id: 16,
            img: portfolioList16Small,
            title: '2023부산세계박람회 <br/> 대한항공 특별기 공개행사',
            desc: '2030부산세계박람회 성공유치 기원 대한항공의 특별기 래핑 유치홍보 공개 행사',
            desc2: '기획, 연출 및 제작',
            client: '대한항공',
            date: '2023.05',
            place: 'Incheon International Airport, Korea',
            category: 'Branding, Public',
            images: portfolio16,
            others: [
                {
                    id: 8,
                    img: portfolioOther1601,
                    title: 'KE-DL JV 5th <br/> ANNIVERSARY PROMOTION',
                    desc: '대한항공-델타항공 조인트벤처 5주년 기념 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '대한항공',
                    date: '2023.08',
                    place: 'Seoul Times Square, Korea',
                    category: 'Branding, Space'
                },
                {
                    id: 30,
                    img: portfolioOther1602,
                    title: '한화그룹 LIFEPLUS <br/> RUNNING TRIBE',
                    desc: '러닝 어드바이저 및 런트립 기획 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '한화그룹',
                    date: '2022.10',
                    place: 'Seoul, Korea',
                    category: 'Branding'
                }
            ]
        },
        {
            id: 17,
            img: portfolioList17,
            // eslint-disable-next-line quotes
            title: "주프랑스한국문화원 특별기획전 <br/> '엑스포 오마주 1900'",
            desc: '2030부산세계박람회 홍보관 전시',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.05',
            place: 'Paris, France',
            category: 'Public, Space',
            images: portfolio17,
            others: [
                {
                    id: 15,
                    img: portfolioOther1701,
                    title: "2030부산세계박람회 유치홍보 <br/> '제834회 함부르크 개항축제'",
                    desc: "2030부산세계박람회 유치홍보 '제834회 함부르크 개항축제' 홍보부스 운영",
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2023.06',
                    place: 'Hamburg, Germany',
                    category: 'Public'
                },
                {
                    id: 18,
                    img: portfolioOther1702,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '엑스포 해양관'",
                    desc: '부산엑스포 유치기원 캠페인 아쿠아리움 전시 기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lotte World Aquarium',
                    date: '2023.05',
                    place: 'Busan, Korea',
                    category: 'Public, Space'
                }
            ]
        }
    ],
    [
        {
            id: 18,
            img: portfolioList18Long,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 캠페인 <br/> '엑스포 해양관'",
            desc: '부산엑스포 유치기원 캠페인 아쿠아리움 전시 기획',
            desc2: '기획, 연출 및 제작',
            client: 'Lotte World Aquarium',
            date: '2023.05',
            place: 'Busan, Korea',
            category: 'Public, Space',
            images: portfolio18,
            others: [
                {
                    id: 17,
                    img: portfolioOther1801,
                    // eslint-disable-next-line quotes
                    title: "주프랑스한국문화원 특별기획전 <br/> '엑스포 오마주 1900'",
                    desc: '2030부산세계박람회 홍보관 전시',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.05',
                    place: 'Paris, France',
                    category: 'Public, Space'
                },
                {
                    id: 20,
                    img: portfolioOther1802,
                    title: '2030부산세계박람회 BIE <br/> 현지실사대응',
                    desc: '2030부산세계박람회 유치활동 BIE 실사단 방문 총괄',
                    desc2: '기획, 연출 및 제작',
                    client: '산업통상자원부',
                    date: '2023.04',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 20,
            img: portfolioList20,
            title: '2030부산세계박람회 BIE <br/> 현지실사대응',
            desc: '2030부산세계박람회 유치활동 BIE 실사단 방문 총괄',
            desc2: '기획, 연출 및 제작',
            client: '산업통상자원부',
            date: '2023.04',
            place: 'Seoul, Busan, Korea',
            category: 'Public',
            images: portfolio20,
            others: [
                {
                    id: 18,
                    img: portfolioOther2001,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 캠페인 <br/> '엑스포 해양관'",
                    desc: '부산엑스포 유치기원 캠페인 아쿠아리움 전시 기획',
                    desc2: '기획, 연출 및 제작',
                    client: 'Lotte World Aquarium',
                    date: '2023.05',
                    place: 'Busan, Korea',
                    category: 'Public, Space'
                },
                {
                    id: 21,
                    img: portfolioOther2002,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 유치기원 행사 '광화에서 빛;나이다'",
                    desc: '실사단 방문 계기 엑스포 붐업 프로젝트',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.03',
                    place: 'Gwanghwamun Seoul, Korea',
                    category: 'Public'
                }
            ]
        },
        {
            id: 21,
            img: portfolioList21,
            // eslint-disable-next-line quotes
            title: "2030부산세계박람회 <br/> 유치기원 행사 '광화에서 빛;나이다'",
            desc: '실사단 방문 계기 엑스포 붐업 프로젝트',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2023.03',
            place: 'Gwanghwamun Seoul, Korea',
            category: 'Public',
            images: portfolio21,
            others: [
                {
                    id: 20,
                    img: portfolioOther2101,
                    title: '2030부산세계박람회 BIE <br/> 현지실사대응',
                    desc: '2030부산세계박람회 유치활동 BIE 실사단 방문 총괄',
                    desc2: '기획, 연출 및 제작',
                    client: '산업통상자원부',
                    date: '2023.04',
                    place: 'Seoul, Busan, Korea',
                    category: 'Public'
                },
                {
                    id: 25,
                    img: portfolioOther2102,
                    title: '2030부산세계박람회 <br/> 조수미 홍보대사 위촉식',
                    desc: '홍보대사 위족식',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2022.12',
                    place: 'LG Art Center Soule, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 25,
            img: portfolioList25,
            title: '2030부산세계박람회 <br/> 조수미 홍보대사 위촉식',
            desc: '홍보대사 위족식',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2022.12',
            place: 'LG Art Center Soule, Korea',
            category: 'Public',
            images: portfolio25,
            others: [
                {
                    id: 21,
                    img: portfolioOther2501,
                    // eslint-disable-next-line quotes
                    title: "2030부산세계박람회 <br/> 유치기원 행사 '광화에서 빛;나이다'",
                    desc: '실사단 방문 계기 엑스포 붐업 프로젝트',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2023.03',
                    place: 'Gwanghwamun Seoul, Korea',
                    category: 'Public'
                },
                {
                    id: 26,
                    img: portfolioOther2502,
                    title: "2030부산세계박람회 <br/> 22년도 유치활동 종합용역 <br/> 'CRUISE82'",
                    desc: '제171차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치의원회',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 26,
            img: portfolioList26,
            title: "2030부산세계박람회 <br/> 22년도 유치활동 종합용역 <br/> 'CRUISE82'",
            desc: '제171차 BIE 총회 계기 파리 현지 홍보',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치의원회',
            date: '2022.11',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio26,
            others: [
                {
                    id: 25,
                    img: portfolioOther2601,
                    title: '2030부산세계박람회 <br/> 조수미 홍보대사 위촉식',
                    desc: '홍보대사 위족식',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2022.12',
                    place: 'LG Art Center Soule, Korea',
                    category: 'Public'
                },
                {
                    id: 27,
                    img: portfolioOther2602,
                    title: '제15회 마포나루 새우젓축제',
                    desc: '축제 총괄 기획 및 연출 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '마포구',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        },
        {
            id: 27,
            img: portfolioList27,
            title: '제15회 마포나루 새우젓축제',
            desc: '축제 총괄 기획 및 연출 운영',
            desc2: '기획, 연출 및 제작',
            client: '마포구',
            date: '2022.11',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio27,
            others: [
                {
                    id: 26,
                    img: portfolioOther2701,
                    title: "2030부산세계박람회 <br/> 22년도 유치활동 종합용역 <br/> 'CRUISE82'",
                    desc: '제171차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치의원회',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 28,
                    img: portfolioOther2702,
                    title: '2030부산세계박람회 <br/> X4 SHOWCASE',
                    desc: '홍보대사 팬사인회',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2022.10',
                    place: 'Lotte World Seoul, Korea',
                    category: 'Public, Space'
                }
            ]
        }
    ],
    [
        {
            id: 28,
            img: portfolioList28,
            title: '2030부산세계박람회 <br/> X4 SHOWCASE',
            desc: '홍보대사 팬사인회',
            desc2: '기획, 연출 및 제작',
            client: '부산시',
            date: '2022.10',
            place: 'Lotte World Seoul, Korea',
            category: 'Public, Space',
            images: portfolio28,
            others: [
                {
                    id: 27,
                    img: portfolioOther2801,
                    title: '제15회 마포나루 새우젓축제',
                    desc: '축제 총괄 기획 및 연출 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '마포구',
                    date: '2022.11',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 32,
                    img: portfolioOther2802,
                    title: '2022 해외한국문화축제',
                    desc: '전세계 대상 한류 콘텐츠 문화행사 기획',
                    desc2: '기획, 연출 및 제작',
                    client: '문화체육관광부',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 32,
            img: portfolioList32,
            title: '2022 해외한국문화축제',
            desc: '전세계 대상 한류 콘텐츠 문화행사 기획',
            desc2: '기획, 연출 및 제작',
            client: '문화체육관광부',
            date: '2022.06',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio32,
            others: [
                {
                    id: 28,
                    img: portfolioOther3201,
                    title: '2030부산세계박람회 <br/> X4 SHOWCASE',
                    desc: '홍보대사 팬사인회',
                    desc2: '기획, 연출 및 제작',
                    client: '부산시',
                    date: '2022.10',
                    place: 'Lotte World Seoul, Korea',
                    category: 'Public, Space'
                },
                {
                    id: 33,
                    img: portfolioOther3202,
                    title: '2030부산세계박람회 <br/> 22년도 유치활동 종합용역',
                    desc: '제170차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                }
            ]
        },
        {
            id: 33,
            img: portfolioList33,
            title: '2030부산세계박람회 <br/> 22년도 유치활동 종합용역',
            desc: '제170차 BIE 총회 계기 파리 현지 홍보',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2022.06',
            place: 'Paris, France',
            category: 'Public',
            images: portfolio33,
            others: [
                {
                    id: 32,
                    img: portfolioOther3301,
                    title: '2022 해외한국문화축제',
                    desc: '전세계 대상 한류 콘텐츠 문화행사 기획',
                    desc2: '기획, 연출 및 제작',
                    client: '문화체육관광부',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 38,
                    img: portfolioOther3302,
                    title: '김포평화축제 <br/> 주간 총괄 기획 · 제작',
                    desc: '주간 총괄 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '김포 문화 재단',
                    date: '2021.10',
                    place: 'Gimpo Art Village, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 38,
            img: portfolioList38Long,
            title: '김포평화축제 <br/> 주간 총괄 기획 · 제작',
            desc: '주간 총괄 기획 및 제작',
            desc2: '기획, 연출 및 제작',
            client: '김포 문화 재단',
            date: '2021.10',
            place: 'Gimpo Art Village, Korea',
            category: 'Public',
            images: portfolio38,
            others: [
                {
                    id: 33,
                    img: portfolioOther3801,
                    title: '2030부산세계박람회 <br/> 22년도 유치활동 종합용역',
                    desc: '제170차 BIE 총회 계기 파리 현지 홍보',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2022.06',
                    place: 'Paris, France',
                    category: 'Public'
                },
                {
                    id: 48,
                    img: portfolioOther3802,
                    title: '2030부산세계박람회 <br/> 마스터플랜 수립',
                    desc: '주제개발, 회장조성, 전시연출 등 마스터플랜 수립',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2020.06',
                    place: 'Seoul, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 48,
            img: portfolioList48,
            title: '2030부산세계박람회 <br/> 마스터플랜 수립',
            desc: '주제개발, 회장조성, 전시연출 등 마스터플랜 수립',
            desc2: '기획, 연출 및 제작',
            client: '2030부산세계박람회 유치위원회',
            date: '2020.06',
            place: 'Seoul, Korea',
            category: 'Public',
            images: portfolio48,
            others: [
                {
                    id: 38,
                    img: portfolioOther4801,
                    title: '김포평화축제 <br/> 주간 총괄 기획 · 제작',
                    desc: '주간 총괄 기획 및 제작',
                    desc2: '기획, 연출 및 제작',
                    client: '김포 문화 재단',
                    date: '2021.10',
                    place: 'Gimpo Art Village, Korea',
                    category: 'Public'
                },
                {
                    id: 55,
                    img: portfolioOther4802,
                    title: '한국마사회 경마공원 콘서트 하반기',
                    desc: '콘서트 기획 및 연출',
                    desc2: '',
                    client: '한국마사회',
                    date: '2019.09',
                    place: 'Gwacheon, Korea',
                    category: 'Public'
                }
            ]
        },
        {
            id: 55,
            img: portfolioList55Small,
            title: '한국마사회 경마공원 콘서트 하반기',
            desc: '콘서트 기획 및 연출',
            desc2: '',
            client: '한국마사회',
            date: '2019.09',
            place: 'Gwacheon, Korea',
            category: 'Public',
            images: portfolio55,
            others: [
                {
                    id: 48,
                    img: portfolioOther5501,
                    title: '2030부산세계박람회 <br/> 마스터플랜 수립',
                    desc: '주제개발, 회장조성, 전시연출 등 마스터플랜 수립',
                    desc2: '기획, 연출 및 제작',
                    client: '2030부산세계박람회 유치위원회',
                    date: '2020.06',
                    place: 'Seoul, Korea',
                    category: 'Public'
                },
                {
                    id: 56,
                    img: portfolioOther5502,
                    title: '강남페스티벌 인터내셔널 프린지',
                    desc: '2019 강남페스티벌 인터네셔널 프린지 공연 기획 및 운영',
                    desc2: '',
                    client: '강남구',
                    date: '2019.09',
                    place: 'Seoul, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 56,
            img: portfolioList56Long,
            title: '강남페스티벌 인터내셔널 프린지',
            desc: '2019 강남페스티벌 인터네셔널 프린지 공연 기획 및 운영',
            desc2: '',
            client: '강남구',
            date: '2019.09',
            place: 'Seoul, Korea',
            category: 'Public',
            images: portfolio56,
            others: [
                {
                    id: 55,
                    img: portfolioList55,
                    title: '한국마사회 경마공원 콘서트 하반기',
                    desc: '콘서트 기획 및 연출',
                    desc2: '',
                    client: '한국마사회',
                    date: '2019.09',
                    place: 'Gwacheon, Korea',
                    category: 'Public'
                },
                {
                    id: 60,
                    img: portfolioOther5602,
                    title: '한국마사회 경마공원 콘서트 상반기',
                    desc: '콘서트 기획 및 연출',
                    desc2: '',
                    client: '한국마사회',
                    date: '2019.06',
                    place: 'Gwacheon, Korea',
                    category: 'Public'
                }
            ]
        }
    ],
    [
        {
            id: 60,
            img: portfolioList60Long,
            title: '한국마사회 경마공원 콘서트 상반기',
            desc: '콘서트 기획 및 연출',
            desc2: '',
            client: '한국마사회',
            date: '2019.06',
            place: 'Gwacheon, Korea',
            category: 'Public',
            images: portfolio60,
            others: [
                {
                    id: 56,
                    img: portfolioOther6001,
                    title: '강남페스티벌 인터내셔널 프린지',
                    desc: '2019 강남페스티벌 인터네셔널 프린지 공연 기획 및 운영',
                    desc2: '',
                    client: '강남구',
                    date: '2019.09',
                    place: 'Seoul, Korea',
                    category: 'Public'
                },
                {
                    id: 1,
                    img: portfolioOther6002,
                    title: 'GSAT 2024',
                    desc: 'GSAT 2024 총괄 대행 운영',
                    desc2: '기획, 연출 및 제작',
                    client: '경상남도',
                    date: '2024.04',
                    place: 'Chagwon, Korea',
                    category: 'Public'
                }
            ]
        }
    ]
];
